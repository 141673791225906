import React, { Component } from 'react'
// import { graphql } from '@apollo/client/react/hoc'
// import _chunk from 'lodash/chunk'
import qs from 'querystring'
// import _get from 'lodash/get'
// import _find from 'lodash/find'
// import jwtDecode from 'jwt-decode'

import PhotoReportList from './PhotoReportList'

// import eventsQuery from '../graphql/queries/events.gql'
import UserPermission from './UserPermission'

// @graphql(eventsQuery, {
//   name: 'events',
//   options: {
//     'cache-policy': 'network-only'
//   }
// })
class ReportedPhotos extends Component {
  state = {}

  handleEventChange = (eventId) => {
    let queryString = qs.parse(this.props.location.search.substr(1))
    queryString.eventId = eventId
    queryString.page = 1
    const { history } = this.props
    history.push(`/photos/report?` + qs.stringify(queryString))
  }

  handlePageChange = (page) => {
    let queryString = qs.parse(this.props.location.search.substr(1))
    queryString.page = page
    const { history } = this.props
    history.push(`/photos/report?` + qs.stringify(queryString))
  }

  render () {
    // const { loading } = this.props.events
    // if (loading) {
    //   return <div>Loading...</div>
    // }

    const { view, page } = qs.parse(this.props.location.search.substr(1))

    if (!view) {
      let queryString = qs.parse(this.props.location.search.substr(1))
      queryString.view = 'image'
      const { history } = this.props
      history.push(`/photos/report?` + qs.stringify(queryString))
    }

    return (<UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'customerService', 'accounting']}>
      <div>
        { <PhotoReportList page={page || 1} onPageChange={this.handlePageChange} />}
      </div>
    </UserPermission>)
  }
}
export default ReportedPhotos
