import { FileExcelOutlined, PictureOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { List, Affix, Typography, Pagination, Input, DatePicker, Tag, Modal, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import { compose } from 'recompose'
import EventSelect from '~/components/EventSelect'
import ExportData from '../components/ExportData'
import PaymentMethod from '~/components/PaymentMethod'
import OrderDetail from '../components/OrderDetail'
import AdminUserSelect from '~/components/AdminUserSelect'
import config from '../config'
import orderListQuery from '../graphql/queries/orderList.gql'

const paymentMethodMap = {
  undefined: 'รหัสอ้างอิง',
  all: 'รหัสอ้างอิง',
  omise_card: 'เลขบัตร 4 ตัวสุดท้าย',
  omise_truemoney: 'เบอร์โทรศัพท์',
  // omise_netbank: '',
  linepay_web: 'transaction id',
  scb_qr: 'reference 2'
}

const paymentMethodInput = {
  omise_card: 'Credit/Debit Card',
  omise_truemoney: 'Truemoney',
  omise_netbank: 'Netbank',
  omise_duitnow_obw: 'DuitNow Online Banking/Wallets',
  omise_touchngo: `Touch 'n Go`,
  omise_boost: 'Boost',
  omise_points_citi: 'Rewards Points',
  midtrans_card: 'Other Debit/Credit Card',
  midtrans_card_maybank : 'Maybank Debit / Credit Card',
  midtrans_qris: 'Pay with QRIS',
  linepay_web: 'Linepay',
  scb_qr: 'QR Code',
  OMISE: 'OMISE',
}


const Orders = compose(
  inject('router'), // TODO: remove mobx dependency, sync router with context or read from url directly
  observer,
  inject('user')
)(({ user, router }) => {
  const { location } = router
  const {
    s: search,
    p: page,
    e: eventId,
    from: startDate,
    to: endDate,
    m: method,
    ref: references,
    // ph: clientPhone,
    pho: photographerId,
    o: orderId
    // pic: photoId,
  } = location.query
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  const intl = useIntl()
  const variables = {
    eventId,
    photographerId,
    search,
    method,
    references,
    completedAtStart: moment(startDate).toISOString(),
    completedAtEnd: moment(endDate).endOf('day').toISOString(),
    page: parseInt(page, 10) || 1
  }
  const { loading, error, data } = useQuery(orderListQuery, {
    variables,
    skip: !startDate && !endDate,
  })
  if (error) {
    console.error(error)
  }

  useEffect(() => {
    // if (sessionStorage.getItem('noticeModal') === 'unseen') {
    //   setVisible(true)
    // }
    if (!location.query.from && !location.query.to) {
      location.query = {
        ...location.query,
        from: moment().subtract(1, 'week').format(moment.HTML5_FMT.DATE),
        to: moment().format(moment.HTML5_FMT.DATE)
      }
      // setFieldsValue({ date: [moment().subtract(1, 'week'), moment()], email, event, method })
      // setEventsSearch()
    }
  }, [location.query.from, location.query.to, location.query])
  const { currentPage, itemCount, perPage } = data?.orderList?.pageInfo ?? {}
  const { items } = data?.orderList ?? {}

  // const paymentMethodMap = (method) => {
  //   all: '',
  //   omise_card: 'Credit/Debit Card',
  //   omise_truemoney: 'Truemoney',
  //   // omise_netbank: '',
  //   linepay_web: 'Linepay',
  //   scb_qr: 'QR Code',
  // }
  // console.log({ data }, perPage)
  const handlePageChange = (page) => {
    location.query = {
      ...location.query,
      p: page
    }
  }

  const handleCompletedDateRange = ([$startDate, $endDate]) => {
    const { p, ...query } = location.query
    location.query = {
      ...query,
      ...(($startDate && $endDate) && {
        from: $startDate.format(moment.HTML5_FMT.DATE),
        to: $endDate.format(moment.HTML5_FMT.DATE)
      })
    }
  }
  const handleReferenceChange = (references) => {
    const { ref: _references, p, ...query } = location.query
    location.query = {
      ...query,
      ...(references && { ref: references })
    }
  }

  const handleSearchChange = (search) => {
    const { s: _search, p, ...query } = location.query
    location.query = {
      ...query,
      ...(search && { s: search })
    }
  }

  // const handleMethodName = (method) => {
  //   switch (method) {
  //     case 'all':
  //       return ''
  //     case 'omise_card':
  //       return 'Credit/Debit Card'
  //     case 'omise_truemoney':
  //       return 'Truemoney'
  //     case 'linepay_web':
  //       return 'Linepay'
  //     case 'scb_qr':
  //       return 'QR Code'
  //   }
  // }

  const handlePhotographerChange = (photographerId) => {
    const { pho: _photographerId, p, ...query } = location.query
    location.query = {
      ...query,
      ...((photographerId && photographerId !== 'all') && { pho: photographerId })
    }
  }

  const handleMethodChange = (method) => {
    const { m: _method, p, ref, ...query } = location.query
    location.query = {
      ...query,
      ...(method && { m: method })
    }
  }
  const handleOrderDetail = (orderId) => {
    const { o: _orderId, ...query } = location.query
    location.query = {
      ...query,
      ...(orderId && { o: orderId })
    }
  }

  const handleEventChange = (eventId) => {
    const { e: _eventId, p, ...query } = location.query
    location.query = {
      ...query,
      ...(eventId && { e: eventId })
    }
  }
  const handleExportData = (data) => {
    const rolesMaster = ['superadmin', 'admin', 'operation_admin', 'accounting', 'customerService']
    const roleMasterCheck = rolesMaster.includes(user.role)
    return (
      data?.orderList?.items?.map((item) => {
        const ordershares = item.ordershares
        const roleMapOrderShares = ordershares.reduce(
          (prev, curr) => ({
            ...prev,
            ...(prev[curr.role] ? { [curr.role]: { amount: prev[curr.role]['amount'] + curr.amount } } : { [curr.role]: { amount: curr.amount } }),
          }),
          {}
        )
        return {
          [intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })]: item.event.title,
          [intl.formatMessage({ id: 'app.orderCompletedDate', defaultMessage: 'Payment Date' })]: moment(item.completedAt).format('lll'),
          ...(['superadmin', 'admin', 'operation_admin', 'accounting', 'customerService'].includes(user.role) && {
            [intl.formatMessage({ id: 'app.email', defaultMessage: 'Email' })]: item.clientEmail,
          }),
          [intl.formatMessage({ id: 'app.orderID', defaultMessage: 'Order ID' })]: item.code,
          [intl.formatMessage({ id: 'app.orderRefId', defaultMessage: 'Order Ref ID' })]: item._id,
          [intl.formatMessage({ id: 'app.photoItems', defaultMessage: 'Photo Items' })]: item.hasUnlimitedItems ? '∞' : item.itemCount,
          [intl.formatMessage({ id: 'app.packages', defaultMessage: 'Packages' })]: item.photoPackage && item.photoPackage.name,
          [intl.formatMessage({ id: 'app.sales', defaultMessage: 'Sales' })]: rolesMaster.includes(user.role)
            ? item.shareableAmount
            : item.currentUserShare?.amount,
          ...(roleMasterCheck && {
            [intl.formatMessage({ id: 'app.photographer', defaultMessage: 'Photographer' })]: roleMapOrderShares['photographer']
              ? roleMapOrderShares['photographer']['amount']
              : 0,
            [intl.formatMessage({ id: 'app.organizer', defaultMessage: 'Organizer' })]: roleMapOrderShares['organizer']
              ? roleMapOrderShares['organizer']['amount']
              : 0,
            [intl.formatMessage({ id: 'app.brokerTitle', defaultMessage: 'Broker' })]: roleMapOrderShares['broker']
              ? roleMapOrderShares['broker']['amount']
              : 0,
            [intl.formatMessage({ id: 'app.platform', defaultMessage: 'Platform' })]: roleMapOrderShares['null']
              ? roleMapOrderShares['null']['amount']
              : 0,
            [intl.formatMessage({ id: 'app.paymentservice', defaultMessage: 'Paymentservice' })]: roleMapOrderShares['paymentservice']
              ? roleMapOrderShares['paymentservice']['amount']
              : 0,
            [intl.formatMessage({ id: 'app.salepersonTitle', defaultMessage: 'Saleperson' })]: roleMapOrderShares['salesperson']
              ? roleMapOrderShares['salesperson']['amount']
              : 0,
          }),
        }
      }) ?? []
    )
  }
  const handleExportIncomeData = (data) => {
    const exportData = []
    data.orderList.items.forEach((item) => {
      const { code, completedAt, event, orderDate, platform, shares } = item ?? {}
      const { title } = event ?? {}
      shares.forEach((share) => {
        ['broker', 'organizer', 'photographer', 'salesperson'].includes(share.role) && exportData.push({
          'role': share.role,
          'amount': share.amount,
          'orderCompletedAt': completedAt,
          'Display name': share.user?.displayName,
          'orderCode': code,
          'platform': platform,
          'orderDate': orderDate,
          'event': title,
        })
      })
    })
    return exportData
  }
  // if (!perPage) {
  //   return 'loading...'
  // }

  return <>
    <List
      loading={loading}
      header={    
        <Affix offsetTop={0}>
          <div style={{ textAlign: 'center', background: 'white', padding: '.5em 0' }}> 
            <Input.Group compact={!isMobile}>
              <EventSelect value={eventId} onChange={handleEventChange}
                style={isMobile
                  ? { width: '100%' }
                  : { width: 260 }
                }
              />{/* TODO: use acl system */}
              <DatePicker.RangePicker
                style={isMobile
                  ? { width: '100%', marginTop: '.5em' }
                  : { width: 260 }
                }
                format="ll"
                timePicker={false}
                // ranges={{ 'last month': [moment().subtract(1, 'month'), moment()] }}
                value={(startDate && endDate) ? [moment(startDate), moment(endDate)] : []}
                defaultValue={(startDate && endDate) ? [moment(startDate), moment(endDate)] : []}
                onChange={handleCompletedDateRange}
              />
              {/* <Button type='primary' icon={<SearchOutlined />} /> */}
            </Input.Group>
            <Input.Group compact>
              {/* photographer select, email, method, search */}
            </Input.Group>
          </div>
          {['admin', 'superadmin', 'operation_admin', 'customerService', 'accounting'].includes(user.role) && <div style={{ textAlign: 'center', background: 'white', padding: '.5em 0' }}>
            <Input.Group compact={!isMobile}>
              <Input.Search
                allowClear
                // value={email}
                onSearch={handleSearchChange}
                // onChange={handleSearchChange}
                style={isMobile
                  ? { width: '100%', marginTop: '.5em' }
                  : { width: 260 }
                } placeholder={intl.formatMessage({ id: 'app.emailOrOrderCode', defaultMessage: 'Email or Order Code' })}
              />
              <PaymentMethod onChange={handleMethodChange} value={method} style={isMobile
                ? { width: '100%', marginTop: '.5em' }
                : { width: 260 }
              }/>
              {paymentMethodInput[method] && <Input.Search
                allowClear
                style={isMobile
                  ? { width: '100%', marginTop: '.5em' }
                  : { width: 260 }
                }
                placeholder={paymentMethodMap[method]}
                // value={handleMethodName(method)}
                onSearch={handleReferenceChange}
              />
              }
              <AdminUserSelect
                value={photographerId}
                onChange={handlePhotographerChange}
                role={'photographer'}
                selectAll
                style={isMobile
                  ? { width: '100%', marginTop: '.5em' }
                  : { width: 260 }
                } />
            </Input.Group>
          </div>}
          {['admin', 'superadmin', 'broker', 'organizer', 'salesperson', 'photographer'].includes(user.role) && (
            <div
              style={isMobile ? { width: '100%', textAlign: 'right' } : { position: 'absolute', top: '20px', left: '100%', transform: 'translateX(-100%)', textAlign: 'right' }}
            >
              {!!items?.length && (
                <ExportData
                  exportedData={handleExportData}
                  fileName={`Sales-${moment().format('DD_MM_YYYY')}.xlsx`}
                  filter={variables}
                  lazyQuery={orderListQuery}
                  sheetNames={['Sales']}
                >
                  <FileExcelOutlined /> {intl.formatMessage({ id: 'app.export', defaultMessage: 'Export' })}
                </ExportData>
              )}
              {!!items?.length && (
                <ExportData
                  exportedData={handleExportIncomeData}
                  fileName={`Report-orders-${moment().format('YYYY_MM_DD')}.xlsx`}
                  filter={variables}
                  // filter={{ variables: { _operators: { _id: { in: orderIds } } } }}
                  lazyQuery={orderListQuery}
                  sheetNames={['Report-sales']}
                  style={{ marginTop: '8px' }}
                >
                  <FileExcelOutlined /> {intl.formatMessage({ id: 'app.incomeExport', defaultMessage: 'Income export' })}
                </ExportData>
              )}
            </div>
          )}
         
        </Affix>
      
      }
      dataSource={items}
      itemLayout="horizontal"
      renderItem={order => (
        <List.Item
          style={{ position: 'relative' }}
          actions={[<>
            {order.hasUnlimitedItems
              ? <Tooltip>&infin; <PictureOutlined /></Tooltip>
              : (['photographer'].includes(user.role) // TODO: handle legacy multi event orders for organizers and brokers
                ? <Tooltip title={`sold ${order.accessibleItemCount} photos of total ${order.itemCount} photos for this order`}>{order.accessibleItemCount} / {order.itemCount} <PictureOutlined /></Tooltip>
                : <Tooltip>{order.itemCount} <PictureOutlined /></Tooltip>
              )
            }
          </>]}
          // extra={}
        >
          <List.Item.Meta title={order.event?.title} description={moment(order.completedAt).format('lll')} />
          {/* <Tag style={{ position: 'absolute', top: '1em', right: 0}}>+1000 THB</Tag> */}
          <div style={{ position: 'absolute', top: '1em', right: 0, textAlign: 'right' }}>
            {['superadmin', 'admin', 'operation_admin', 'accounting', 'customerService'].includes(user.role)
              ? <Typography.Text strong style={{ color: '#52c41a' }}>
                + {intl.formatNumber(order.shareableAmount, { style: 'currency', currency: config.currency, minimumFractionDigits: 2, currencyDisplay: 'code' })}
                {/* + {intl.formatNumber(order.shareableAmount, { minimumFractionDigits: 2 })} THB */}
                {/* {record.creditAmount > 0 && '+'}<FormattedNumber value={record.creditAmount} /> */}
              </Typography.Text>
              : <Typography.Text strong style={{ color: '#52c41a' }}>
                + {intl.formatNumber(order.currentUserShare?.amount, { style: 'currency', currency: config.currency, minimumFractionDigits: 2, currencyDisplay: 'code' })}
                {/* + {intl.formatNumber(order.currentUserShare?.amount, { minimumFractionDigits: 2 })} THB */}
                {/* {record.creditAmount > 0 && '+'}<FormattedNumber value={record.creditAmount} /> */}
              </Typography.Text>}
            <br />
            {['superadmin', 'admin', 'operation_admin', 'accounting', 'customerService'].includes(user.role) &&
              order.couponCodes?.map(coupon => <Tag key={coupon._id} style={{ marginRight: 0, marginLeft: 4 }} color="blue">{coupon.couponCodeType.name}</Tag>)
            }
            {order.photoPackage &&
              <Tag style={{ marginRight: 0, marginLeft: 4 }} color="orange">{order.photoPackage.name}</Tag>
            }
          </div>
          <a onClick={() => handleOrderDetail(order._id)}>{order.code}</a> {['superadmin', 'admin', 'operation_admin', 'accounting', 'customerService'].includes(user.role) && order.clientEmail}{/* eslint-disable-line jsx-a11y/anchor-is-valid */}
        </List.Item>
      )}
      // pagination={!loading && {
      //   style: { textAlign: 'center' },
      //   simple: true,
      //   current: currentPage,
      //   total: itemCount,
      //   pageSize: perPage,
      //   itemRender: (page, type, $pagination) => <Affix offsetBottom={8}>{$pagination}</Affix>,
      //   onChange: handlePageChange,
      // }}
    />

    {(!loading && itemCount > 0) && <Affix offsetBottom={0}>
      <div style={{ textAlign: 'center', background: 'white', padding: '.5em 0' }}>
        <div>{itemCount} items</div>
        <Pagination
          simple
          // showTotal={(total, range) => `${total} ${range}`}
          current={currentPage}
          total={itemCount}
          pageSize={perPage}
          onChange={handlePageChange}
          // itemRender={(page, type, $pagination) => <Affix offsetBottom={8}>{$pagination}</Affix>}
        />
      </div>
    </Affix>}
    {Boolean(orderId) && <Modal open onCancel={() => handleOrderDetail(null)} footer={null}>
      <OrderDetail orderId={orderId} />
    </Modal>}
  </>
})

export default Orders
