import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { message, Select } from 'antd'
import { useQuery } from '@apollo/client'
import couponCodesPaginationQuery from '../graphql/queries/couponCodesPagination.gql'
import _escapeRegExp from 'lodash/escapeRegExp'

const CouponSelect = ({ onChange, couponTypeId, couponId = 'all' }) => {
  const [search, setSearch] = useState('')
  const { error, loading, data } = useQuery(couponCodesPaginationQuery, {
    variables: {
      couponCodeTypeId: couponTypeId === 'all' ? undefined : couponTypeId,
      code: search,
    },
  })

  if (error) {
    return message.error(error)
  }

  const handleSearch = (input) => (input ? setSearch(`/${_escapeRegExp(input)}/i`) : setSearch())

  return (
    <Select
      showSearch
      loading={loading}
      filterOption={false}
      onSearch={handleSearch}
      onChange={onChange}
      value={couponId}
      style={{ width: 220, marginBottom: '1em' }}
    >
      <Select.Option key={'all'} value={'all'}>
        <FormattedMessage id='app.allCoupons' defaultMessage='All Coupons' />
      </Select.Option>
      {data?.couponCodesPagination?.items?.map((item) => (
        <Select.Option key={item._id} value={item._id}>
          {item.code}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CouponSelect
