import React from 'react'
import { Col } from 'antd'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import '../styles/dashboard.css'
// import eventCountQuery from '~/graphql/queries/eventCount.gql'
import orderTotalAndAvgQuery from '~/graphql/queries/orderTotalAndAvg.gql'

const DashboardStats = ({ date, income }) => {
  let startDate = moment(date, 'DD/MM/YYYY').startOf('day')
  let endDate = moment(date, 'DD/MM/YYYY').endOf('day')

  const intl = useIntl()
  // const { error: eventCountErr, loading: eventCountLoading, data: eventCountData } = useQuery(eventCountQuery, {
  //   variables: {
  //     startAndEnd: [
  //       { "_operators": { "startDate": { "gte": startDate } } }, 
  //       { "_operators": { "startDate": { "lte": endDate } } }
  //     ]
  //   }
  // })
  // const { error: eventCountPartnerErr, loading: eventCountPartnerLoading, data: eventCountPartnerData } = useQuery(eventCountQuery, {
  //   variables: {
  //     officialPartner: true,
  //     rental: { "ne": 'free' },
  //     startAndEnd: [
  //       { "_operators": { "startDate": { "gte": startDate } } }, 
  //       { "_operators": { "startDate": { "lte": endDate } } }
  //     ]
  //   }
  // })
  // const { error: eventCountNonPartnerErr, loading: eventCountNonPartnerLoading, data: eventCountNonPartnerData } = useQuery(eventCountQuery, {
  //   variables: {
  //     nonPartner: { "ne": true },
  //     rental: { "ne": 'free' },
  //     startAndEnd: [
  //       { "_operators": { "startDate": { "gte": startDate } } }, 
  //       { "_operators": { "startDate": { "lte": endDate } } }
  //     ]
  //   }
  // })
  const { error: orderTAAErr, loading: orderTAALoading, data: orderTAAData } = useQuery(orderTotalAndAvgQuery, {
    variables: {
      "startDate": startDate,
      "endDate": endDate
    }
  })
  // const { error: lastYearOrderTAAErr, loading: lastYearOrderTAALoading, data: lastYearOrderTAAData } = useQuery(orderTotalAndAvgQuery, {
  //   variables: {
  //     "startDate": moment(startDate).year(moment(date).year - 1),
  //     "endDate": moment(endDate).year(moment(date).year() - 1)
  //   }
  // })

  const { orderTotalAndAvg } = orderTAAData || {}
  // const { orderTotalAndAvg: lastYearOrderTotalAndAvg } = lastYearOrderTAAData || {}
  // const { eventCount } = eventCountData || {}
  // const { eventCount: eventCountPartner } = eventCountPartnerData || {}
  // const { eventCount: eventCountNonPartner } = eventCountNonPartnerData || {}
  if (orderTAAErr) {
    return 'error'
  }
  if (orderTAALoading) {
    return 'loading...'
  }

  return (<>
    <Col xs={5} style={{ marginBottom: '10px' }} >
      {moment(date, 'DD/MM/YYYY').isSame(moment(), 'day') ? 'Today' : moment(date, 'DD/MM/YYYY').format('DD-MM-YY')}
    </Col>
    <Col xs={5} style={{ marginBottom: '10px' }} >
      N/A
    </Col>
    <Col xs={5} style={{ marginBottom: '10px' }} >
      {intl.formatNumber(orderTotalAndAvg?.avg.toFixed(2))}
    </Col>
    <Col xs={4} style={{ marginBottom: '10px' }} >
      N/A
    </Col>
    {/* <Col xs={3} style={{ marginBottom: '10px' }} >
      {(eventCountPartner / eventCount * 100).toFixed(2)}
    </Col>
    <Col xs={3} style={{ marginBottom: '10px' }} >
      {(eventCountNonPartner / eventCount * 100).toFixed(2)}
    </Col> */}
    <Col xs={5} style={{ marginBottom: '10px' }} >
      {(((income - orderTotalAndAvg?.organizerShareTotal - orderTotalAndAvg?.photographerShareTotal - orderTotalAndAvg?.brokerShareTotal) / income) * 100).toFixed(2)}
    </Col>
  </>)
}

export default DashboardStats
