import React from 'react'
import { useQuery } from '@apollo/client'
// import photographerQuery from '~/graphql/queries/photographer.gql'
import photographerFinancialQuery from '~/graphql/queries/photographerFinancial.gql'
import { Spin } from 'antd'
import FinancialInfo from './Financialnfo'

const PhotographerInfo = ({ photographerId }) => {
  const { error, loading, data } = useQuery(photographerFinancialQuery, {
    variables: {
      _id: photographerId
    }
  })
  if (loading) {
    return <Spin />
  }
  if (error) {
    return 'Error...'
  }

  const { share, transfer, balance } = data?.photographerFinancial ?? {}
  return <FinancialInfo share={share} transfer={transfer} balance={balance} />
}

export default PhotographerInfo
