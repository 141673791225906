import React, { useState } from 'react'
import path from 'path'
import url from 'url'
import { Upload, Button } from 'antd'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import cloudUploadMutation from '~/graphql/mutations/cloudUpload.gql'
import loadImage from 'blueimp-load-image'
import axios from 'axios'
import _get from 'lodash/get'
import { FormattedMessage } from 'react-intl'

const resizeImg = async (file, options = {}, quality = 1) => {
  const { width, height, ...resizeOptions } = options
  const { image, originalWidth, originalHeight } = await loadImage(file, {
    ...resizeOptions,
    orientation: true,
    canvas: true
  })
  let canvas
  if (width && height) {
    canvas = document.createElement('CANVAS')
    canvas.width = width ?? resizeOptions.maxWidth ?? originalWidth
    canvas.height = height ?? resizeOptions.maxHeight ?? originalHeight
    canvas.getContext('2d').drawImage(image, 0, 0)
  } else {
    canvas = image
  }

  return new Promise(resolve => canvas.toBlob(resolve, file.type, quality))
}

const CloudImageUploader = React.forwardRef(({ children, onChange, value, fileMiddleware, afterResize, resize = {}, provider = 'gs', path: fullPath, fileName, accept = 'image/*', isTemporary, placeholderUrl }, ref) => {
  const {
    mode, // contain / cover
    ...resizeOptions
  } = resize
  if (mode && !['contain', 'cover'].includes(mode)) {
    throw new Error('RESIZE_MODE_UNSUPPORTED')
  }
  const options = {
    ...resizeOptions,
    contain: mode === 'contain',
    cover: mode === 'cover'
  }
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [upload] = useMutation(cloudUploadMutation)
  const onError = () => setError(true)

  const processFile = async (file) => {
    const _blob = await resizeImg(file, options)
    const blob = afterResize ? afterResize(_blob) : _blob
    const response = await upload({
      variables: {
        provider,
        path: fullPath,
        mimeType: file.type,
        fileName,
        isTemporary
      }
    })
    const { uploadUrl, downloadUrl } = _get(response, 'data.upload', {})
    if (uploadUrl) {
      try {
        if (provider === 'cf') {
          const form = new FormData()
          form.append('file', new File([blob], 'file'))
          await fetch(uploadUrl, {
            method: 'POST',
            body: form
          })
          setLoading(false)
          onChange(downloadUrl)
          return
        }
        await axios.put(uploadUrl, blob, {
          headers: {
            'Content-Type': file.type,
            ...(provider === 'gs' && {
              'x-goog-acl': 'public-read'
            })
          }
        })
      } catch (err) {
        console.log(err)
      }

      setLoading(false)
      onChange(downloadUrl)
    }
  }

  const customRequest = async ({ file }) => {
    setError(false)
    setLoading(true)
    // const file = fileMiddleware ? await fileMiddleware(_file) : _file
    if (fileMiddleware) {
      return fileMiddleware(file, processFile)
    }
    return processFile(file)
  }
  const onRemove = () => {
    onChange()
  }
  const fileList = value ? [{
    uid: value,
    name: path.basename(url.parse(value).pathname),
    status: 'done',
    url: value,
    thumbUrl: value
  }] : []
  const src = value ?? placeholderUrl
  return <Upload ref={ref} accept={accept} customRequest={customRequest} fileList={fileList} showUploadList={false} onRemove={onRemove}>
    {loading
      ? <Button disabled icon={<LoadingOutlined />}><FormattedMessage id="app.upload" defaultMessage="Upload" />...</Button>
      : src && !error
        ? <Button style={{ height: 'initial' }}>
          <img src={src} onError={onError} alt="" style={{ maxHeight: 100 }} />
        </Button>
        : <Button icon={<UploadOutlined />} loading={loading}>
          {children ?? <FormattedMessage id="app.upload" defaultMessage="Upload" />}
        </Button>

    }
  </Upload>
})

export default CloudImageUploader
