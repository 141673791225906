import React, { useEffect, useState } from "react";
import { Button, Spin, Typography, Select, Modal } from "antd";
import { ArrowDownOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { useIntl } from "react-intl";
import { useMutation, useQuery } from "@apollo/client";
import ORGANIZERSALL from "~/graphql/queries/organizersAll.gql";
import MERGEORGANIZERS from "~/graphql/mutations/mergeOrganizers.gql";

export default function Mergeorganizers() {
  const [organizersIds, setOrganizersIds] = useState(null);
  const [organizerId, setOrganizerId] = useState(null);
  const [organizers, setOrganizers] = useState(null);
  const [newId, setNewId] = useState(null);
  const [manualTransfer, setManualTransfer] = useState(null);
  const [orderShare, setOrderShare] = useState(null);
  const [event, setEvent] = useState(null);
  const [organizer, setOrganizer] = useState(null);
  const [adminUser, setAdminUser] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const intl = useIntl();
  const { Text } = Typography;
  const { Option } = Select;

  const organizerAll = useQuery(ORGANIZERSALL, {
    variables: {
      filter: {
        AND: [{ _operators: { name: { ne: null } } }, { deleted: false }],
      },
    },
  });

  const [mergeOrganizers, mergeOrganizersResult] = useMutation(MERGEORGANIZERS);

  useEffect(() => {
    if (organizerAll.data) {
      setOrganizers(organizerAll.data.organizers);
    }
  }, [organizerAll.data]);

  useEffect(() => {
    if (!mergeOrganizersResult.data) {
      return;
    }

    if (
      mergeOrganizersResult.data &&
      !mergeOrganizersResult.data.mergeOrganizer
    ) {
      handleError(
        intl.formatMessage({ id: "app.mergeOrganizer.error.api.userrole" })
      );
      return;
    }
    const {
      manualTransferResult,
      orderShareResult,
      eventResult,
      organizerResult,
      adminUserResult,
    } = mergeOrganizersResult.data.mergeOrganizer;

    if (manualTransferResult) {
      setManualTransfer(manualTransferResult);
    }
    if (orderShareResult) {
      setOrderShare(orderShareResult);
    }
    if (eventResult) {
      setEvent(eventResult);
    }

    if (organizerResult) {
      setOrganizer(organizerResult);
    }
    if (adminUserResult) {
      setAdminUser(adminUserResult);
    }

    setOrganizersIds(null);
    setOrganizerId(null);
    organizerAll.refetch();
    // eslint-disable-next-line
  }, [mergeOrganizersResult.data]);

  const handleSubmit = () => {
    if (!organizerId || !organizersIds || organizersIds.length === 0) return;

    const duplicateId = organizersIds.find((el) => el === organizerId);
    if (duplicateId) {
      handleError(
        intl.formatMessage({ id: "app.mergeOrganizer.error.duplicateInput" })
      );
      return;
    }
    setShowResult(true);
    try {
      mergeOrganizers({
        variables: { _ids: organizersIds, newId: organizerId },
      });
    } catch (error) {
      setShowResult(false);
      handleError(intl.formatMessage({ id: "app.mergeOrganizer.error.api" }));
      return;
    }
  };

  const handleError = (message) => {
    Modal.error({
      content: message,
      okText: intl.formatMessage({ id: "app.ok" }),
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Select
          key={
            mergeOrganizersResult.data &&
            intl.formatMessage({
              id: "app.mergeOrganizer.tobeMerge",
            })
          }
          showSearch
          mode="multiple"
          size="default"
          placeholder={intl.formatMessage({
            id: "app.mergeOrganizer.tobeMerge",
          })}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          onChange={(value) => {
            setOrganizersIds(value);
            setShowResult(false);
          }}
          style={{ width: "100%", flexGrow: 1, flexShrink: 1, resize: "none" }}
        >
          {organizers &&
            organizers.map((data) => (
              <Option
                key={data.name}
                value={data._id}
              >{`Code:${data._id.slice(-4)} Name:${data.name}  OrganizerShare:${data.organizerShare}   PhotographerShare:${data.photographerShare}`}</Option>
            ))}
        </Select>
        <ArrowDownOutlined style={{ fontSize: 28, margin: "2%" }} />
        <Select
          key={
            mergeOrganizersResult.data &&
            intl.formatMessage({
              id: "app.mergeOrganizer.toMerge",
            })
          }
          showSearch
          placeholder={intl.formatMessage({
            id: "app.mergeOrganizer.toMerge",
          })}
          optionFilterProp="children"
          onChange={(value) => {
            setOrganizerId(value);
            setNewId(value);
            setShowResult(false);
          }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          style={{
            width: "100%",
            paddign: "1%",
            resize: "none",
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          {organizers &&
            organizers.map((data) => (
              <Option
                key={data.name}
                value={data._id}
              >{`Code:${data._id.slice(-4)} Name:${data.name}  OrganizerShare:${data.organizerShare}   PhotographerShare:${data.photographerShare}`}</Option>
            ))}
        </Select>

        <Button
          type="submit"
          style={{ width: "20%", margin: "2%" }}
          onClick={handleSubmit}
        >
          {intl.formatMessage({
            id: "app.submit",
          })}
        </Button>
      </div>
      {showResult && (
        <div>
          <div
            style={{
              alignSelf: "start",
              display: "flex",
              flexDirection: "column",
              width: "80%",
            }}
          >
            {mergeOrganizersResult.loading && <Spin />}
            {mergeOrganizersResult.data && (
              <div>
                {manualTransfer && (
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleOutlined
                        theme="twoTone"
                        twoToneColor="#52c41a"
                        style={{ fontSize: 32, margin: "2%" }}
                      />
                      <Text>{`${intl.formatMessage({
                        id: "app.mergeOrganizer.ManualTransferResult.title",
                      })} ${newId} }`}</Text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {manualTransfer.map((data, i) => (
                        <Text key={i}>
                          {`
                    userId :${data.userId},${intl.formatMessage({
                            id: "app.modified",
                          })}:${data.modified}`}
                        </Text>
                      ))}
                    </div>
                  </div>
                )}
                {orderShare && (
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleOutlined
                        theme="twoTone"
                        twoToneColor="#52c41a"
                        style={{ fontSize: 32, margin: "2%" }}
                      />
                      <Text>
                        {`${intl.formatMessage({
                          id: "app.mergeOrganizer.OrderShareResult.title",
                        })} ${newId} }`}
                      </Text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {orderShare.map((data, i) => (
                        <Text key={i}>
                          {`
                    userId :${data.userId},${intl.formatMessage({
                            id: "app.modified",
                          })}:${data.modified}`}
                        </Text>
                      ))}
                    </div>
                  </div>
                )}
                {event && (
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleOutlined
                        theme="twoTone"
                        twoToneColor="#52c41a"
                        style={{ fontSize: 32, margin: "2%" }}
                      />
                      <Text>
                        {`${intl.formatMessage({
                          id: "app.mergeOrganizer.EventResult.title",
                        })} ${newId} }`}
                      </Text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {event.map((data, i) => (
                        <Text key={i}>
                          {`
                    organizerId :${data.organizerId},${intl.formatMessage({
                            id: "app.modified",
                          })}:${data.modified}`}
                        </Text>
                      ))}
                    </div>
                  </div>
                )}
                {adminUser && (
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleOutlined
                        theme="twoTone"
                        twoToneColor="#52c41a"
                        style={{ fontSize: 32, margin: "2%" }}
                      />
                      <Text>
                        {`${intl.formatMessage({
                          id: "app.mergeOrganizer.AdminUserResult.title",
                        })} ${newId} }`}
                      </Text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {adminUser.map((data, i) => (
                        <Text key={i}>
                          {`
                    _id :${data._id},${intl.formatMessage({
                            id: "app.deleted",
                          })}:${data.modified}`}
                        </Text>
                      ))}
                    </div>
                  </div>
                )}
                {organizer && (
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleOutlined
                        theme="twoTone"
                        twoToneColor="#52c41a"
                        style={{ fontSize: 32, margin: "2%" }}
                      />
                      <Text>
                        {`${intl.formatMessage({
                          id: "app.mergeOrganizer.OrganizerResult.title",
                        })} ${newId} }`}
                      </Text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {organizer.map((data, i) => (
                        <Text key={i}>
                          {`
                    _id :${data._id},${intl.formatMessage({
                            id: "app.deleted",
                          })}:${data.modified}`}
                        </Text>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
