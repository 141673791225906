import React,{useEffect, useState} from 'react'
// import { FormattedMessage } from "react-intl"
import { useQuery } from "@apollo/client"
import { ResponsiveLine } from "@nivo/line"
import moment from 'moment'
import { List, Affix, DatePicker} from 'antd'
import { inject, observer } from "mobx-react"
import _get from "lodash/get"
import { compose } from "recompose"
import monthlyOrderSharesQuery from "~/graphql/queries/monthlyOrderShares.gql"
import { useIntl } from 'react-intl'
// import currentAdminUserQuery from '~/graphql/queries/currentAdminUser.gql'

const MonthStats = compose(
  inject("router"), // TODO: remove mobx dependency, sync router with context or read from url directly
  observer,
  inject("user")
)(({ user, router }) => {
  const intl = useIntl()
  const { location } = router;
  const { from: startDate, to: endDate } = location.query;
  const [monthPickerOpen, setMonthPickerOpen] = useState(false)
  // const start = moment(startDate).toISOString();
  // const end = moment(endDate).endOf('month').toISOString();
  // console.log({ startDate, endDate })
  const { data: monthlyOrderSharesData} = useQuery(monthlyOrderSharesQuery, {
    variables: {
        start: moment(startDate).startOf('month').toISOString(),
        end: moment(endDate).endOf('month').toISOString(),
      }
  });

  useEffect(() => {
    if (!location.query.from && !location.query.to) {
      location.query = {
        ...location.query,
        from: moment().subtract(12, 'month').format(moment.HTML5_FMT.MONTH),
        to: moment().format(moment.HTML5_FMT.MONTH),
      }
    }
  }, [location.query.from, location.query.to, location.query])

  // const { data: currentAdminUserData } = useQuery(currentAdminUserQuery)
  // const currentAdminUserId = _get(currentAdminUserData, "currentAdminUser._id");
  // const currentAdminUserRole = _get(currentAdminUserData, "currentAdminUser.role");


  if(!['superadmin', 'admin', 'operation_admin'].includes(user.role)){
    return <div>Access denied</div>
  }
// console.log(monthlyOrderSharesData ,start,end)


 
  const handleCompletedDateRange = ([$startDate, $endDate]) => {
    location.query = {
      ...location.query,
      ...(($startDate && $endDate) && {
        from: $startDate.format(moment.HTML5_FMT.MONTH),
        to: $endDate.format(moment.HTML5_FMT.MONTH)
      })
    }
  }
  // console.log(startDate,endDate)
  const monthlyOrderShares = _get(monthlyOrderSharesData, "monthlyOrderShares", []);

  const orderShares = [
    ...monthlyOrderShares.map((ordershare) => ({
      x: ordershare._id,
      y: ordershare.totalAmount,
    })),
  ];

  const orderSharesData = [
    {
      id: "ยอดขาย",
      data: orderShares,
    },
  ];
  // console.log(orderSharesddd)

  return (
    <>
      <List>
        <Affix offsetTop={0}>
          <div style={{ textAlign: 'center', background: 'white', padding: '.5em 0' }}>
              <DatePicker.RangePicker
                open={monthPickerOpen}
                format="MMMM YYYY"
                bordered={false}
                mode={['month', 'month']}
                timePicker={false}
                onFocus={() => setMonthPickerOpen(true)}
                onBlur={() => setMonthPickerOpen(false)}
                onPanelChange={(...values) => {
                  setMonthPickerOpen(false)
                  handleCompletedDateRange(...values)
                }}
                // ranges={{ 'last month': [moment().subtract(1, 'month'), moment()] }}
                value={(startDate && endDate) ? [moment(startDate), moment(endDate)] : []}
                defaultValue={(startDate && endDate) ? [moment(startDate), moment(endDate)] : []}
                onChange={handleCompletedDateRange}
                />
          </div>
        </Affix>
      </List>
      <div style={{ height: '460px', width: '100%' }}>
        <ResponsiveLine
          data={orderSharesData}
          height={460}
          margin={{ top: 50, right: 60, bottom: 100, left: 100 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            // legend: "transportation",
            format: _id => moment(_id).format('MMM YYYY')
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "จำนวนเงิน",
            legendOffset: -80,
            legendPosition: "middle",
            format: value => intl.formatNumber(value)
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          tooltip={({ point }) => intl.formatNumber(point.data.y)}
          pointLabel="y"
          enableGridX={false}
        />
      </div>
    </>
  );
});

export default MonthStats;
