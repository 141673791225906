import React, { useState } from 'react'
// import { observer } from 'mobx-react-lite'
import { Form, Input, Row, Col, Layout, message, Result, Spin, Button } from 'antd'
import { CheckOutlined, LockOutlined } from '@ant-design/icons'
import { useStores } from './contexts'
import { useQuery, useMutation } from '@apollo/client'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import userByPasswordResetQuery from '~/graphql/queries/userByPasswordResetToken.gql'
import updateAdminUserMutation from '~/graphql/mutations/updateAdminUser.gql'
import moment from 'moment'

const EventManage = () => {
  const [form] = Form.useForm()
  const { getFieldValue } = form
  const { routerStore: router } = useStores()
  const [passwordReset, setPasswordReset] = useState(false)
  const { token: resetPasswordToken } = router.location.query
  const { loading, data } = useQuery(userByPasswordResetQuery, {
    variables: {
      resetPasswordToken,
    },
  })
  const [updateAdminUser] = useMutation(updateAdminUserMutation)
  const handleSubmit = async (values) => {
    const {
      userByResetPasswordToken: { _id },
    } = data
    delete values.record.confirmPassword
    const {
      record: { password },
    } = values
    try {
      await updateAdminUser({
        variables: {
          _id,
          record: {
            password,
            tokenExpiredOn: moment(),
          },
        },
      })
      setPasswordReset(true)
    } catch (err) {
      err.graphQLErrors.forEach((error) => message.error(error.message))
    }
  }
  const checkConfirm = (rule, value, callback) => {
    if (value && value !== getFieldValue(['record', 'confirmPassword'])) {
      return Promise.reject(new Error('Two passwords that you enter is inconsistent!'))
    }
    return Promise.resolve()
  }
  const checkPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue(['record', 'password'])) {
      return Promise.reject(new Error('Two passwords that you enter is inconsistent!')) // eslint-disable-line
    }
    return Promise.resolve()
  }

  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  if (data && !data.userByResetPasswordToken) {
    return (
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        // extra={<Button type="primary">Back Home</Button>}
      />
    )
  }
  if (data && moment() > moment(data.userByResetPasswordToken.tokenExpiredOn)) {
    return (
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        // extra={<Button type="primary">Back Home</Button>}
      />
    )
  }
  return (
    <Layout style={{ background: '#fff' }}>
      <Layout.Content>
        <Row type='flex' justify='center' align='middle'>
          <Col xs={22} md={8} lg={6} style={{ borderRadius: '10px', boxShadow: '0 2px 8px -3px rgba(0, 0, 0, 0.2)', marginTop: '100px' }}>
            <Form
              form={form}
              onFinish={handleSubmit}
              className='login-form'
              style={{ width: '75%', margin: 'auto', marginTop: '2em', textAlign: 'center' }}
            >
              <div style={{ width: '45px', height: '45px', margin: 'auto', backgroundColor: '#ffaf38', borderRadius: '50%', marginBottom: '50px' }}>
                {passwordReset ? (
                  <CheckOutlined style={{ fontSize: '25px', transform: 'translatey(10px)' }} />
                ) : (
                  <LockOutlined style={{ fontSize: '25px', transform: 'translatey(10px)' }} />
                )}
              </div>
              {passwordReset ? (
                <React.Fragment>
                  <h2 style={{ color: '#000', marginBottom: '20px' }}>Your password has been reset</h2>
                  {/* <h2 style={{ color: '#f08fae', marginBottom: '20px' }}>E-mail sent!</h2> */}
                  <p>Try logging in</p>
                  <p>กรุณาเข้าสู่ระบบอีกครั้ง</p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h2 style={{ color: '#000', marginBottom: '20px' }}>Password Reset</h2>
                  <p>Please enter your new password</p>
                  <p>กรุณากรอกรหัสผ่านใหม่</p>
                  {/* <p>Enter your e-mail address and wait for a password reset e-mail.</p>
                  <p>After you get the e-mail, follow the instruction to reset your password.</p> */}
                </React.Fragment>
              )}
              {/* <Form.Item
                name={['email']}
                rules={[
                  { required: true, message: 'Please enter your e-mail!' },
                  { type: 'email', message: 'Please enter a correct e-mail!' },
                ]}
              >
                <Input
                  prefix={<FontAwesomeIcon icon={['far', 'envelope']} />}
                  placeholder='E-mail'
                  style={{ display: passwordReset ? 'none' : 'inline-block' }}
                />
              </Form.Item> */}
              {!passwordReset && (
                <React.Fragment>
                  <Form.Item
                    name={['record', 'password']}
                    label=''
                    hasFeedback
                    rules={[
                      // { min: 8, message: 'Password must be 8 or more characters long' },
                      { validator: checkConfirm },
                    ]}
                  >
                    <Input prefix={<LockOutlined />} type='password' placeholder='New Password' />
                  </Form.Item>
                  <Form.Item
                    name={['record', 'confirmPassword']}
                    label=''
                    dependencies={['record', 'password']}
                    hasFeedback
                    rules={[{ validator: checkPassword }]}
                  >
                    <Input prefix={<LockOutlined />} type='password' placeholder='Confirm Password' />
                  </Form.Item>
                </React.Fragment>
              )}
              <Form.Item>
                {!passwordReset && (
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='login-form-button'
                    style={{ width: '100%', marginBottom: '10px', display: passwordReset ? 'none' : 'inline-block' }}
                  >
                    Submit
                  </Button>
                )}
                {passwordReset && (
                  <Link to='/login'>
                    <Button style={{ width: '100%' }}>Log in</Button>
                  </Link>
                )}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
}

export default EventManage
