import React from 'react'
import { compose } from 'recompose'
import { inject } from 'mobx-react'

const UserPermission = compose(
  inject('user')
)(({ children, grantedTo, user }) => {
  if (!grantedTo.includes(user.role)) {
    return null
  }

  return (
    <>
      {children}
    </>
  )
})

export default UserPermission
