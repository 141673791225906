import { CloseCircleOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, InputNumber, Modal, Radio, Row } from 'antd'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import config from '../config'

const MINIMUM_SHARE = config?.minimumPercentShare
const MAXIMUM_SHARE = config?.maximumPercentShare

const EventPhotographerForm = ({ defaultValues, form, tab }) => {
  const { getFieldValue } = form
  const intl = useIntl()
  const [termsModal, setTermsModal] = useState(false)
  const handlePhotographerShareModal = (value) => {
    setTermsModal(value)
  }
  return (
    <div
      className={tab === 'photographer' ? 'dropdown' : 'hide'}
      style={{
        border: '1px solid #DFDFDF',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        borderTop: 'none',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        gridTemplateColumns: '1fr 1fr',
        padding: '8px 16px',
      }}
    >
      <Form.Item
        name={['record', 'registrationMethod']}
        label={intl.formatMessage({ id: 'app.registrationMethod', defaultMessage: 'Registration Method' })}
        initialValue={defaultValues?.record?.registrationMethod}
        rules={[{ required: true }]}
      >
        <Radio.Group style={{ width: '100%' }}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={8}>
              <div style={{ border: '1px solid gainsboro', borderRadius: '8px', display: 'grid', alignContent: 'center', height: '40px' }}>
                <Radio value='manual' style={{ marginLeft: '10px' }}>
                  {intl.formatMessage({ id: 'app.registrationMethod.manual', defaultMessage: 'Custom' })}
                </Radio>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div style={{ border: '1px solid gainsboro', borderRadius: '8px', display: 'grid', alignContent: 'center', height: '40px' }}>
                <Radio value='auto' style={{ marginLeft: '10px' }}>
                  {intl.formatMessage({ id: 'app.registrationMethod.auto', defaultMessage: 'Lotto' })}
                </Radio>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div style={{ border: '1px solid gainsboro', borderRadius: '8px', display: 'grid', alignContent: 'center', height: '40px' }}>
                <Radio value='free' style={{ marginLeft: '10px' }}>
                  {intl.formatMessage({ id: 'app.registrationMethod.free', defaultMessage: 'Queue' })}
                </Radio>
              </div>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={['record', 'uploadNotice']}
        label={intl.formatMessage({ id: 'app.uploadNotice', defaultMessage: 'Upload Notice' })}
        initialValue={defaultValues?.record?.uploadNotice}
      >
        <Input.TextArea style={{ height: '80px' }} />
      </Form.Item>
      <Form.Item>
        <Button icon={<TeamOutlined />} style={{ height: '40px' }}>
          {intl.formatMessage({ id: 'app.managePhotographer', defaultMessage: 'Manage Photographer' })}
        </Button>
      </Form.Item>
      <Form.Item
        name={['record', 'photographerShare']}
        label={intl.formatMessage({ id: 'app.photographerShare', defaultMessage: 'Photographer Share' })}
        initialValue={defaultValues?.record?.photographerShare ?? 50}
        hasFeedback
        rules={[{ required: true }, { type: 'number', min: MINIMUM_SHARE, max: MAXIMUM_SHARE }]}
      >
        <InputNumber style={{ maxWidth: '200px', width: '100%' }} />
      </Form.Item>
      <Form.Item>
        <div onClick={() => handlePhotographerShareModal(true)} style={{ color: '#BD188B', cursor: 'pointer', textDecoration: 'underline' }}>
          {intl.formatMessage({ id: 'app.photographerShareTerms', defaultMessage: 'Photographer Share Term of Use' })}
        </div>
      </Form.Item>
      <Modal
        bodyStyle={{ borderRadius: '16px', padding: '24px 40px 12px 40px' }}
        closable
        closeIcon={<CloseCircleOutlined />}
        footer={
          <Row gutter={[12, 0]}>
            <Col xs={24} sm={24}>
              <Button
                onClick={() => handlePhotographerShareModal(false)}
                style={{
                  background: '#BD188B',
                  color: 'white',
                  width: '100%',
                }}
              >
                {intl.formatMessage({ id: 'app.ok', defaultMessage: 'OK' })}
              </Button>
            </Col>
          </Row>
        }
        maskClosable
        onCancel={() => handlePhotographerShareModal(false)}
        open={termsModal}
        width={280}
      >
        <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
          <div>{`${intl.formatMessage({ id: 'app.organizerShare', defaultMessage: 'Organizer Share' })}: `}</div>
          <div style={{ textAlign: 'end' }}>{`${MAXIMUM_SHARE - getFieldValue(['record', 'photographerShare'])}%`}</div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
          <div>{`${intl.formatMessage({ id: 'app.photographerShare', defaultMessage: 'Photographer Share' })}: `}</div>
          <div style={{ textAlign: 'end' }}>{`${getFieldValue(['record', 'photographerShare'])}%`}</div>
        </div>
      </Modal>
    </div>
  )
}

export { EventPhotographerForm as default }
