import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from '@apollo/client'
import { Spin, Button, Form, Input, Select, message, InputNumber } from 'antd'

import partnerQuery from '~/graphql/queries/organizer.gql'
import updateOrganizerMutation from '~/graphql/mutations/updateOrganizer.gql'
import CloudImageUploader from '~/components/CloudImageUploader'
import supportedBanks from '~/data/supportedBanks.json'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 10,
    },
  },
}

const Partner = ({ history, match }) => {
  const [form] = Form.useForm()
  const { getFieldValue, resetFields } = form
  const intl = useIntl()
  const { data, loading, error } = useQuery(partnerQuery, {
    variables: {
      _id: match.params.partnerId,
    },
  })
  const [updateOrganizer] = useMutation(updateOrganizerMutation)
  const { organizer: partner } = data ?? {}
  const handleSubmit = async (values) => {
    const {
      address,
      companyName,
      formalName,
      identityDocumentUrl,
      name,
      nationalId,
      organizerShare,
      organizerShareUnit,
      phone,
      photographerShare,
      bankAccount: { brand, name: accountName, number, passbookImageUrl },
    } = values
    try {
      await updateOrganizer({
        variables: {
          _id: match.params.partnerId,
          record: {
            address,
            companyName,
            formalName,
            identityDocumentUrl,
            name,
            nationalId,
            organizerShare,
            organizerShareUnit,
            phone,
            photographerShare,
            bankAccount: {
              brand,
              name: accountName,
              number,
              passbookImageUrl,
            },
          },
        },
      })
      message.info(`Partner "${name}" updated`, 5)
      resetFields()
      history.push('/partners')
    } catch (err) {
      console.error(err)
    }
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  if (error) {
    return 'Error'
  }
  return (
    <div style={{ padding: '10px' }}>
      <div
        style={{
          width: '1000px',
          margin: 'auto',
          marginTop: '2em',
          border: '1px solid #707070',
          borderBottom: 'none',
          textAlign: 'center',
          padding: '10px',
        }}
      >
        {intl.formatMessage({ id: 'app.editPartner', defaultMessage: 'Edit Partner' })}
      </div>
      <Form
        form={form}
        onFinish={handleSubmit}
        colon={false}
        style={{ width: '1000px', margin: 'auto', paddingTop: '20px', border: '1px solid #707070' }}
      >
        <Form.Item
          {...formItemLayout}
          name={['name']}
          label={intl.formatMessage({ id: 'app.partnerName', defaultMessage: 'Partner Name' })}
          initialValue={partner?.name}
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['formalName']}
          label={intl.formatMessage({ id: 'app.formalName', defaultMessage: 'Formal Name' })}
          initialValue={partner?.formalName}
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['companyName']}
          label={intl.formatMessage({ id: 'app.companyName', defaultMessage: 'Company Name' })}
          initialValue={partner?.companyName}
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['nationalId']}
          label={intl.formatMessage({ id: 'app.citizen/tax', defaultMessage: 'Citizen ID/Tax ID' })}
          initialValue={partner?.nationalId}
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['address']}
          label={intl.formatMessage({ id: 'app.partnerAddress', defaultMessage: 'Address' })}
          initialValue={partner?.address}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['phone']}
          label={intl.formatMessage({ id: 'app.phone', defaultMessage: 'Phone' })}
          initialValue={partner?.phone}
        >
          <Input />
        </Form.Item>
        <div>
          <Form.Item
            {...formItemLayout}
            name={['bankAccount', 'brand']}
            label={intl.formatMessage({ id: 'app.selectBank', defaultMessage: 'Select Bank' })}
            initialValue={partner?.bankAccount?.brand}
            rules={[
              {
                required: getFieldValue('link_bank_account'),
                message: intl.formatMessage({ id: 'app.pleaseSelectBank', defaultMessage: 'Please Select Bank!' }),
              },
            ]}
          >
            <Select>
              <Select.Option key={'none'} value='none'>
                {intl.formatMessage({ id: 'app.selectBank', defaultMessage: 'Select Bank' })}
              </Select.Option>
              {supportedBanks.map((bankId) => (
                <Select.Option key={bankId} value={bankId}>
                  {intl.formatMessage({ id: `app.banks.${bankId}` })}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['bankAccount', 'number']}
            label={intl.formatMessage({ id: 'app.accountNumber', defaultMessage: 'Account Number' })}
            initialValue={partner?.bankAccount?.number}
            rules={[
              {
                required: getFieldValue('link_bank_account'),
                len: 10,
                message: intl.formatMessage({ id: 'app.pleaseInputYourAccountNumber', defaultMessage: 'Please input your account number!' }),
              },
            ]}
          >
            <Input placeholder={intl.formatMessage({ id: 'app.accountNumber', defaultMessage: 'Account Number' })} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['bankAccount', 'name']}
            label={intl.formatMessage({ id: 'app.accountName', defaultMessage: 'Account Name' })}
            initialValue={partner?.bankAccount?.name}
            rules={[
              {
                required: getFieldValue('link_bank_account'),
                message: intl.formatMessage({ id: 'app.pleaseInputYourAccountName', defaultMessage: 'Please input your account name!' }),
              },
            ]}
          >
            <Input placeholder={intl.formatMessage({ id: 'app.accountName', defaultMessage: 'Account Name' })} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['bankAccount', 'passbookImageUrl']}
            label={intl.formatMessage({ id: 'app.passbookImageUrl', defaultMessage: 'Bank Account Book' })}
            initialValue={partner?.bankAccount?.passbookImageUrl}
            // rules={[{ ...(!isAdmin && { required: true }), message: intl.formatMessage({ id: 'app.passbookRequired', defaultMessage: 'Please upload document' }) }]}
          >
            <CloudImageUploader provider='gs' path='passbooks' />
          </Form.Item>
        </div>
        <Form.Item
          {...formItemLayout}
          name={['identityDocumentUrl']}
          label={intl.formatMessage({ id: 'app.identityDocument', defaultMessage: 'Identity Document' })}
          initialValue={partner?.identityDocumentUrl}
          // rules={[{ ...(!isAdmin && { required: true }), message: intl.formatMessage({ id: 'app.passbookRequired', defaultMessage: 'Please upload document' }) }]}
        >
          <CloudImageUploader provider='gs' path='ids' />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'app.partnerShare', defaultMessage: 'Partner Share' })}
          style={{ marginBottom: 0 }}
        >
          <Form.Item name={['organizerShare']} initialValue={partner?.organizerShare} style={{ display: 'inline-block', width: '40%' }}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name={['organizerShareUnit']}
            initialValue={partner?.organizerShareUnit}
            style={{ display: 'inline-block', width: '30%', padding: '0px 10px' }}
          >
            <Select>
              <Select.Option value='fixed'>{intl.formatMessage({ id: 'app.baht', defaultMessage: 'Baht' })}</Select.Option>
              <Select.Option value='percentage'>%</Select.Option>
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item {...formItemLayout} label={intl.formatMessage({ id: 'app.photographerShare', defaultMessage: 'Photographer Share' })}>
          <Form.Item name={['photographerShare']} initialValue={partner?.photographerShare} style={{ display: 'inline-block', width: '40%' }}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item style={{ display: 'inline-block', width: '30%', padding: '0px 10px' }}>
            <span>%</span>
          </Form.Item>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type='primary' htmlType='submit' style={{ marginRight: '10px' }}>
            {intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' })}
          </Button>
          <Link to='/partners'>
            <Button type='default'>{intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancel' })}</Button>
          </Link>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Partner
