import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import compose from 'recompose/compose'
import { Form, InputNumber, Button, Select, Input, Spin } from 'antd'
import couponCodeTypesPaginationQuery from '../graphql/queries/couponCodeTypesPagination.gql'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 10,
    },
  },
}

const CouponForm = compose(injectIntl)(({ defaultValues, onSubmit, intl }) => {
  const [form] = Form.useForm()
  const { getFieldValue, resetFields } = form
  const { error: couponCodeTypesError, loading: couponCodeTypesLoading, data: couponCodeTypesData } = useQuery(couponCodeTypesPaginationQuery)
  const [loading, setLoading] = useState(false)

  if (couponCodeTypesError) {
    return 'Error'
  }
  if (loading || couponCodeTypesLoading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }

  const { items } = couponCodeTypesData?.couponCodeTypesPagination ?? {}
  const handleFormSubmit = async (values) => {
    setLoading(true)
    const { couponCodeTypeId, copies, copiesPerUser, quantity, prefix, length } = values
    try {
      await onSubmit({
        couponCodeTypeId,
        copies,
        copiesPerUser,
        quantity,
        prefix: prefix.toUpperCase(),
        length,
      })
      resetFields()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }
  const handleRequiredMessage = ({ id, msg }) =>
    `${intl.formatMessage(
      { id: 'app.fieldRequired', defaultMessage: '{field} is required' },
      { field: intl.formatMessage({ id: `app.${id}`, defaultMessage: msg }) }
    )}`

  return (
    <Form form={form} onFinish={handleFormSubmit}>
      <Form.Item
        {...formItemLayout}
        name={['couponCodeTypeId']}
        label={<FormattedMessage id='app.couponType' defaultMessage='Coupon type' />}
        initialValue={defaultValues}
        rules={[{ required: true, message: handleRequiredMessage({ id: 'couponType', msg: 'Coupon type' }) }]}
      >
        <Select placeholder={<FormattedMessage id='app.pleaseSelect' defaultMessage='Please Select' />} required style={{ width: '90%' }}>
          {items?.map((item) => (
            <Select.Option key={item._id} value={item._id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['copies']}
        label={<FormattedMessage id='app.couponMaxUse' defaultMessage='Max use per coupon' />}
        rules={[{ required: true, message: handleRequiredMessage({ id: 'couponMaxUse', msg: 'Max use per coupon' }) }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['copiesPerUser']}
        label={<FormattedMessage id='app.couponMaxUsePerUser' defaultMessage='Max use per user (email)' />}
        rules={[{ required: true, message: handleRequiredMessage({ id: 'couponMaxUsePerUser', msg: 'Max use per user (email)' }) }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['quantity']}
        label={<FormattedMessage id='app.quantity' defaultMessage='Quantity' />}
        rules={[{ required: true, message: handleRequiredMessage({ id: 'quantity', msg: 'Quantity' }) }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item {...formItemLayout} name={['prefix']} label={<FormattedMessage id='app.prefix' defaultMessage='Prefix' />} initialValue={''}>
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name={['length']}
        label={<FormattedMessage id='app.length' defaultMessage='Length' />}
        initialValue={10}
        rules={[{ required: true, message: handleRequiredMessage({ id: 'length', msg: 'Length' }) }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() => (
          <Form.Item {...formItemLayout} label={<FormattedMessage id='app.example' defaultMessage='Example' />}>
            <span>{getFieldValue(['prefix']) + '*'.repeat(getFieldValue(['length']) - getFieldValue(['prefix']).length ?? 0)}</span>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type='primary' htmlType='submit'>
          <FormattedMessage id='app.submit' defaultMessage='Submit' />
        </Button>
      </Form.Item>
    </Form>
  )
})

export default CouponForm
