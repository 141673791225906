import React from 'react'
import compose from 'recompose/compose'
import { inject, observer } from 'mobx-react'
// import { Radio } from 'antd'

// const RadioButton = Radio.Button
// const RadioGroup = Radio.Group

const LocaleSwitcher = compose(
  inject('i18n'),
  observer
)(({ i18n }) => (
  <div>
  {/* {console.log({ i18n })} */}
     {['en', 'th'].map((l, i) => (
       <span
        style={{
          padding: '.25em',
          ...(i18n.locale === l && {
            borderBottom: '3px solid orange'
          })
        }}
        onClick={() => i18n.setLocale(l)}
        key={l}
      >
         {l.toUpperCase()}
       </span>
     ))}
  </div>
  // <RadioGroup defaultValue={i18n.locale}>
  //   {['en', 'th'].map((l, i) => (
  //     <RadioButton value={l} onClick={() => i18n.setLocale(l)} key={l}>
  //       {l.toUpperCase()}
  //     </RadioButton>
  //   ))}
  // </RadioGroup>
))

export default LocaleSwitcher
