import React from 'react'
import { Modal, Tag, List, Typography, Alert } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { FormattedNumber, injectIntl, FormattedMessage } from 'react-intl'
import { useMutation, useQuery } from '@apollo/client'
import QRCode from 'qrcode'
import { compose } from 'recompose'
import _get from 'lodash/get'
import purchaseCreditsMutation from '~/graphql/mutations/purchaseCredits.gql'
import currentAdminUserCreditsQuery from '~/graphql/queries/currentAdminUserCredits.gql'
import config from '../config'
const packages = [1000, 2000, 3000, 4000, 5000]
const handleReload = () => {
  window.scrollTo(0, 0)
  window.location.reload()
  // TODO: use updateQuery instead
}
const BuyCredits = compose(
  injectIntl
)(({ intl }) => {
  const [purchaseCredits] = useMutation(purchaseCreditsMutation)
  const { error, loading, data } = useQuery(currentAdminUserCreditsQuery, { fetchPolicy: 'network-only' })
  const total = _get(data, 'currentAdminUser.creditBalance.total')
  const creditPricePer1000 = _get(data, 'creditPricePer1000')
  const [modal, contextHolder] = Modal.useModal()
  const handleCreateOrder = async (creditAmount) => {
    // console.log({ creditAmount })
    const { errors, data } = await purchaseCredits({ variables: { creditAmount, method: 'scb_qr', params: {} } })
    if (errors) {
      console.error(errors)
    }
    // console.log({ data })
    const source = _get(
      data,
      'purchaseCredits.data.scb_qr.source'
    )
    if (source) {
      const qrCode = await QRCode.toDataURL(source, { scale: 8 })
      modal.confirm({
        icon: null,
        maskClosable: true,
        centered: true,
        okButtonProps: { icon: <ReloadOutlined /> },
        okText: intl.formatMessage({
          id: 'app.payment.method.scb_qr.reload',
          defaultMessage: 'Check status'
        }),
        onOk: handleReload,
        cancelText: intl.formatMessage({ id: 'app.action.cancel', defaultMessage: 'Cancel' }),
        content: <React.Fragment>
          <Alert
            style={{ whiteSpace: 'pre-line' }}
            message={intl.formatMessage({
              id: 'app.messages.payment.method.scb_qr',
              defaultMessage: 'Scan this QR Code with a mobile application of any Thai bank \n or save this QR Code and open in the application \n using the scan to pay function'
            })}
          />
          <img src={qrCode} alt="PromptPay QR" style={{ width: '100%' }} />
          <div style={{ textAlign: 'center', fontSize: 'x-large' }}>
            <FormattedNumber value={creditAmount / 1000 * creditPricePer1000} style="currency" currency={config.currency} currencySign="standard" />{/* eslint-disable-line react/style-prop-object */}
          </div>
          <div style={{ textAlign: 'center' }}>
            {intl.formatMessage({
              id: 'app.amountCredits',
              defaultMessage: '{amount} credits'
            }, {
              amount: intl.formatNumber(creditAmount)
            })}
          </div>
        </React.Fragment>
      })
    }
  }
  if (error) {
    return 'Error'
  }
  if (loading) {
    return 'Loading...'
  }
  // const total = _get(data, 'currentAdminUser.creditBalance.total')
  return <React.Fragment>
    {contextHolder}
    <div className="rounded-container" style={{ textAlign: 'center' }}>
      <Typography.Title level={3}>
        <FormattedMessage id="app.totalCredits" defaultMessage="Total Credits" />
      </Typography.Title>
      <Typography.Text strong style={{ fontSize: '3em' }}>
        <FormattedNumber value={total} />
      </Typography.Text>
    </div>
    <div id="buy-credits" className="rounded-container">
      <List
        header={<Typography.Title level={3}>
          <FormattedMessage id="app.buyCredits" defaultMessage="Buy Credits" />
        </Typography.Title>}
      >
        {packages.map(creditAmount => (
          console.log({ creditPricePer1000 }) ||
          <List.Item key={creditAmount} onClick={() => handleCreateOrder(creditAmount)}>
            <List.Item.Meta
              title={<FormattedNumber value={creditAmount} />}
            />
            <Tag color="blue">
              <FormattedNumber value={creditAmount / 1000 * creditPricePer1000} style="currency" currency={config.currency} currencySign="standard" />{/* eslint-disable-line react/style-prop-object */}
            </Tag>
          </List.Item>
        ))}
      </List>
    </div>
  </React.Fragment>
})

export default BuyCredits
