import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { Query } from '@apollo/client/react/components'
import { Modal, Button, Tag, Row, Col } from 'antd'
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import compose from 'recompose/compose'
import _get from 'lodash/get'
import moment from 'moment-timezone'
import config from '../config'

import transferRequestQuery from '~/graphql/queries/transferRequest.gql'
import cancelTransferRequestMutation from '~/graphql/mutations/cancelTransferRequest.gql'

const labelLayout = {
  xs: { span: 24 },
  sm: { span: 8 },
  style: {
    fontWeight: 'bold',
    textAlign: 'right'
  }
}

const valueLayout = {
  xs: { span: 24 },
  sm: { span: 16 }
}

const tagColors = {
  pending: 'gold',
  approved: 'blue',
  cancelled: 'volcano',
  rejected: 'red',
  completed: 'green'
}
const TransferRequestDetailsModal = compose(
  inject('user'),
  graphql(cancelTransferRequestMutation, { name: 'cancelTransferRequest' }),
  observer
)(({ user, id, onClose, cancelTransferRequest, ...props }) => {
  const handleCancelTransferRequest = async () => {
    await cancelTransferRequest({
      variables: {
        id
      }
    })
    onClose()
  }
  return <Query query={transferRequestQuery} variables={{ id }} fetchPolicy="network-only">
    {({ data, loading, error }) => {
      if (loading) {
        return <Modal open header={null} footer={null}>
          Loading...
        </Modal>
      }
      if (error) {
        console.error(error)
        return <div>error</div>
      }
      // console.log({ data })
      const { transferRequest } = data ?? {}
      const user = transferRequest?.user ?? {}
      return <Modal
        {...props}
        title="Details"
        open={!!id}
        maskClosable
        onCancel={onClose}
        footer={
          <React.Fragment>
            {transferRequest.status === 'pending' &&
              <Button icon={<CloseCircleOutlined />} type="primary" onClick={handleCancelTransferRequest} danger>Cancel Request</Button>
            }
            <Button icon={<CloseOutlined />} onClick={onClose}><FormattedMessage id="app.action.close" defaultMessage="Close" /></Button>
          </React.Fragment>
        }
      >
        <React.Fragment>
          <Row gutter={8} style={{ marginBottom: '6px' }}>
            <Col {...labelLayout} >ID</Col>
            <Col {...valueLayout} >{_get(transferRequest, '_id')}</Col>
          </Row>
          {(user.role === 'superadmin' || user.role === 'admin' || user.role ==='operation_admin' || user.role === 'accounting') && <Row gutter={8} style={{ marginBottom: '6px' }}>
            <Col {...labelLayout} ><FormattedMessage id="app.photographer" defaultMessage="Photographer" /></Col>
            <Col {...valueLayout} >{_get(transferRequest, 'user.displayName')}</Col>
          </Row>}
          <Row gutter={8} style={{ marginBottom: '6px' }}>
            <Col {...labelLayout} ><FormattedMessage id="app.requestDate" defaultMessage="Request Date" /></Col>
            <Col {...valueLayout} >{moment(transferRequest.dateRequested).format('Do MMM YYYY, HH:mm:ss')}</Col>
          </Row>
          <Row gutter={8} style={{ marginBottom: '6px' }}>
            <Col {...labelLayout} ><FormattedMessage id="app.amountRequested" defaultMessage="Withdrawal Amount" /></Col>
            <Col {...valueLayout} ><FormattedNumber value={transferRequest.amount} minimumFractionDigits={2} /></Col>
          </Row>
          <Row gutter={8} style={{ marginBottom: '6px' }}>
            <Col {...labelLayout} ><FormattedMessage id="app.note" defaultMessage="Note" /></Col>
            <Col {...valueLayout} >{_get(transferRequest, 'note')}</Col>
          </Row>
          <Row gutter={8} style={{ marginBottom: '6px' }}>
            <Col {...labelLayout} ><FormattedMessage id="app.status" defaultMessage="Status" /></Col>
            <Col {...valueLayout} >
              <Tag color={tagColors[transferRequest.status]}>
                <FormattedMessage id={`app.status.transferRequest.${transferRequest.status}`} />
              </Tag>
            </Col>
          </Row>
          <Row gutter={8} style={{ marginBottom: '6px' }}>
            <Col {...labelLayout}><FormattedMessage id='app.userfullname' defaultMessage='Fullname' /></Col>
            <Col {...valueLayout}>{`${user?.profile?.firstName} ${user?.profile?.lastName}`}</Col>
          </Row>
          {config.platform === 'heyjom' && 
          <Row gutter={8} style={{ marginBottom: '6px' }}>
            <Col {...labelLayout}><FormattedMessage id='app.bankICNumber' defaultMessage='IC No.' /></Col>
            <Col {...valueLayout}>{`${user?.profile?.nationalId}`}</Col>
          </Row>
          }
          <Row gutter={8} style={{ marginBottom: '6px' }}>
            <Col {...labelLayout}><FormattedMessage id='app.bankName' defaultMessage='Bank Name' /></Col>
            <Col {...valueLayout}>
            <FormattedMessage id={`app.banks.${user?.bankAccount?.brand}`} defaultMessage='Bank Account' />
            </Col>
          </Row>
          <Row gutter={8} style={{ marginBottom: '6px' }}>
            <Col {...labelLayout}><FormattedMessage id='app.bankAccount' defaultMessage='Bank Account' /></Col>
            <Col {...valueLayout}>
              {user?.bankAccount?.number} ({user?.bankAccount?.name})
            </Col>
          </Row>
          {['approved', 'rejected', 'completed'].includes(transferRequest.status) &&
            <React.Fragment>
              <Row gutter={8} style={{ marginBottom: '6px' }}>
                <Col {...labelLayout} ><FormattedMessage id="app.reviewDate" defaultMessage="Review Date" /></Col>
                <Col {...valueLayout} >{moment(transferRequest.dateApproved).format('Do MMM YYYY, HH:mm:ss')}</Col>
              </Row>
              <Row gutter={8} style={{ marginBottom: '6px' }}>
                <Col {...labelLayout} >Remark</Col>
                <Col {...valueLayout} >{_get(transferRequest, 'remark')}</Col>
              </Row>
              {transferRequest.transfer && (user.role === 'superadmin' || user.role === 'admin' || user.role ==='operation_admin' || user.role === 'accounting') &&
                <React.Fragment>
                  <Row gutter={8} style={{ marginBottom: '6px' }}>
                    <Col {...labelLayout} >Transfer ID</Col>
                    <Col {...valueLayout} >{_get(transferRequest, 'transferId')}</Col>
                  </Row>
                  <Row gutter={8} style={{ marginBottom: '6px' }}>
                    <Col {...labelLayout} >Transfer Fee</Col>
                    <Col {...valueLayout} >{_get(transferRequest, 'transfer.fee') / 100}</Col>
                  </Row>
                  <Row gutter={8} style={{ marginBottom: '6px' }}>
                    <Col {...labelLayout} >Transfer Paid</Col>
                    <Col {...valueLayout} >{JSON.stringify(_get(transferRequest, 'transfer.sent'))}</Col>
                  </Row>
                  <Row gutter={8} style={{ marginBottom: '6px' }}>
                    <Col {...labelLayout} >Transfer Paid</Col>
                    <Col {...valueLayout} >{JSON.stringify(_get(transferRequest, 'transfer.paid'))}</Col>
                  </Row>
                  <Row gutter={8} style={{ marginBottom: '6px' }}>
                    <Col {...labelLayout} >Failure Message</Col>
                    <Col {...valueLayout} >{_get(transferRequest, 'transfer.failure_message')}</Col>
                  </Row>
                </React.Fragment>
              }
            </React.Fragment>
          }
        </React.Fragment>
      </Modal>
    }}
  </Query>
})

/**

requestId
requestedDate
amount
note
status

reviewDate
remark

 */

export default TransferRequestDetailsModal
