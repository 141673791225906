import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { observer } from 'mobx-react'
import { useMutation } from '@apollo/client'
import { compose } from 'recompose'
import { Form, Input, Row, Col, Layout, message, Button } from 'antd'
import { CheckOutlined, LockOutlined, MailOutlined } from '@ant-design/icons'
// import logo from './assets/images/logo.png'
// import jsonwebtoken from 'jsonwebtoken'
import resetPasswordMutation from '~/graphql/mutations/resetPassword.gql'
// import { GradientButton, WhiteButton, GradientText } from '~/styles'
// import phoneImg from  '~/assets/images/phone.png'

const ForgotPassword = compose(
  observer,
  injectIntl
)(({ intl }) => {
  const [form] = Form.useForm()
  const [emailSent, setEmailSent] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [resetPassword] = useMutation(resetPasswordMutation)
  const handleSubmit = async (values) => {
    setSubmitLoading(true)
    try {
      await resetPassword({
        variables: values,
      })
      setEmailSent(true)
    } catch (err) {
      err.graphQLErrors.forEach((error) => message.error(intl.formatMessage({ id: `api.errors.${error.message}`, defaultMessage: 'Error' })))
    }
  }
  return (
    <Layout style={{ background: '#fff' }}>
      <Layout.Content>
        <Row type='flex' justify='center' align='middle'>
          <Col xs={22} md={8} lg={6} style={{ borderRadius: '10px', boxShadow: '0 2px 8px -3px rgba(0, 0, 0, 0.2)', marginTop: '100px' }}>
            <Form
              form={form}
              onFinish={handleSubmit}
              className='login-form'
              style={{ width: '75%', margin: 'auto', marginTop: '2em', textAlign: 'center' }}
            >
              <div style={{ width: '45px', height: '45px', margin: 'auto', backgroundColor: '#ffaf38', borderRadius: '50%', marginBottom: '50px' }}>
                {emailSent ? (
                  // ? <FontAwesomeIcon icon={['fas', 'check']} transform="down-8" style={{ color: '#d3275f', fontSize: '1.5em' }}/>
                  // : <FontAwesomeIcon icon={['fas', 'lock']} transform="down-8" style={{ color: '#d3275f', fontSize: '1.5em' }}/>
                  <CheckOutlined style={{ fontSize: '25px', transform: 'translatey(10px)' }} />
                ) : (
                  <LockOutlined style={{ fontSize: '25px', transform: 'translatey(10px)' }} />
                )}
              </div>
              {emailSent ? (
                <React.Fragment>
                  <h2 style={{ color: '#000', marginBottom: '20px' }}>Check your Inbox for a password reset e-mail.</h2>
                  <h2 style={{ color: '#000', marginBottom: '20px' }}>กรุณาตรวจสอบ e-mail ที่เราส่งไปในกล่องจดหมายของคุณ</h2>
                  {/* <h2 style={{ color: '#f08fae', marginBottom: '20px' }}>E-mail sent!</h2> */}
                  {/* <p>Please follow the instruction in the e-mail.</p> */}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h2 style={{ color: '#000', marginBottom: '20px' }}>Forgot Password</h2>
                  <p>We just need your registered e-mail address to send you a password reset e-mail</p>
                  <p>กรุณากรอก e-mail ที่ลงทะเบียนไว้ เพื่อขอตั้งรหัสผ่านใหม่</p>
                  {/* <p>Enter your e-mail address and wait for a password reset e-mail.</p>
              <p>After you get the e-mail, follow the instruction to reset your password.</p> */}
                </React.Fragment>
              )}
              <Form.Item
                name={['email']}
                rules={[
                  { required: true, message: 'Please enter your e-mail address!' },
                  { type: 'email', message: 'Please enter a valid e-mail address!' },
                ]}
              >
                <Input prefix={<MailOutlined />} placeholder='E-mail' style={{ display: emailSent ? 'none' : 'inline-block' }} />
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  loading={submitLoading}
                  htmlType='submit'
                  className='login-form-button'
                  style={{ width: '100%', marginBottom: '10px', display: emailSent ? 'none' : 'inline-block' }}
                >
                  Submit
                </Button>
                <Link to='/login'>
                  <Button style={{ width: '100%' }}>Log in</Button>
                </Link>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
})

export default ForgotPassword
