import React from 'react'
import { useIntl } from 'react-intl'
// import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
// import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
// import { useMutation, useQuery } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { compose } from 'recompose'
import { Table, Button, Input, Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'
// import moment from 'moment'
import photographerListQuery from '~/graphql/queries/adminUserList.gql'
import config from '../config'

const Photographers = compose(inject('router'))(({ router }) => {
  const { location } = router
  const { p: page, s: search } = location.query
  const intl = useIntl()
  const { error, loading, data } = useQuery(photographerListQuery, {
    variables: {
      search: search || '',
      page: parseInt(page, 10),
      role: 'photographer'
    }
  })
  const { currentPage, itemCount, perPage } = data?.adminUserList?.pageInfo ?? {}
  const handleSearch = (value) => {
    const { p, ...query } = location.query
    location.query = {
      ...query,
      s: value
    }
  }
  const handlePagination = (page) => {
    location.query = {
      ...location.query,
      p: `${page}`
    }
  }
  if (error) {
    return 'Error'
  }
  return (
    <>
      <Row gutter={[6, 12]} type='flex' justify='space-between'>
        <Col xs={24} sm={{ span: 16 }} lg={{ span: 8 }}>
          <Input.Search enterButton allowClear onSearch={handleSearch} />
        </Col>
        {/* <Col xs={24} sm=a{{ span: 8 }} lg={{ span: 4 }}>
        <Link to="/salespersons/add">
          <span><Button block icon={<PlusOutlined />} type="primary" > {intl.formatMessage({ id: 'app.addSalesperson', defaultMessage: 'Add Salesperson' })}</Button></span>
        </Link>
      </Col> */}
      </Row>
      <Row gutter={[6, 12]}>
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={data?.adminUserList?.items}
            rowKey={(record) => record._id}
            pagination={{
              pageSize: perPage,
              current: currentPage,
              total: itemCount,
              onChange: handlePagination
            }}
          >
            {/* <Table.Column
            title='No.'
            dataIndex={['number']}
            key='number'
            render={(text, record, index) => index + 1}
          /> */}
            {/* <Table.Column
              title= 'Number'
              dataIndex={['Number']}
              key='Number'
              // render={(name, { _id }) => <Link to={`/events:balance:salesperson/${_id}`}>{name}</Link>}
            /> */}
            <Table.Column
              title={
                <FormattedMessage id='app.username' defaultMessage='Username' />
              }
              dataIndex={['username']}
              key={['username']}
              // dataIndex={['salespersonData', 'financial', 'balance']}
              // key='salespersonData.financial.balance'
              render={(name, { _id }) => <Link to={`/events:balance:photographer/${_id}`}>{name}</Link>}
            />
            <Table.Column
              title={`${intl.formatMessage({ id: 'app.photographerOutstanding', defaultMessage: 'photographerOutstanding'})} (${config.currency})`}
              dataIndex={['financial', 'transfer']}
              key='financial.transfer'
              render={(balance) => balance?.toFixed(2) || 0}
            />
            <Table.Column
              title={`${intl.formatMessage({ id: 'app.photographerPayable', defaultMessage: 'photographerPayable'})} (${config.currency})`}
              dataIndex={['financial', 'balance']}
              key='financial.balance'
              render={(balance) => balance?.toFixed(2) || 0}
            />
            <Table.Column
              title={
                <FormattedMessage id='app.approvedUploadsCount' defaultMessage='approvedUploadsCount' />
              }
              dataIndex={['approvedUploadsCount']}
              key='approvedUploadsCount'
            />
            <Table.Column
              title={
                <FormattedMessage id='app.rejectedUploadsCount' defaultMessage='rejectedUploadsCount' />
              }
              dataIndex={['rejectedUploadsCount']}
              key='rejectedUploadsCount'
            />

            <Table.Column
              title={intl.formatMessage({ id: 'app.action', defaultMessage: 'Action' })}
              dataIndex={['_id']}
              key='action'
              render={(_id) => (
                <div>
                  <Link to={`/users/${_id}/edit`}>
                    <Button type='link'>{intl.formatMessage({ id: 'app.edit', defaultMessage: 'Edit' })}</Button>
                  </Link>
                </div>
              )}
            />
          </Table>
        </Col>
      </Row>
    </>
  )
})

export default Photographers
