import React from 'react'
import { Result } from 'antd'
import moment from 'moment'

const MaintenanceMessage = ({ start, end, children }) => (
  process.env.NODE_ENV === 'production' && start && moment().isBetween(start, end)
    ? <Result
      // icon={<img src="/images/logo.png" alt="ThaiRun" width={100} />}
      status="info"
      title="PHOTO.THAI.RUN หยุดให้บริการฟังก์ชันสำหรับจัดการภาพชั่วคราว เพื่อปรับปรุงระบบ"
      subTitle="Photo and event management features are temporarily under maintenance"
    />
    : children
)
export default MaintenanceMessage
