import { library } from '@fortawesome/fontawesome-svg-core'

import { faWallet, faCircle, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'


library.add(
  faWallet,
  faCircle,
  faCaretUp,
  faCaretDown
)