import React from 'react'
import { Button, Modal } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import eventItemsQuery from '~/graphql/queries/eventItems.gql'
import removeEventMutation from '~/graphql/mutations/removeEvent.gql'
const EventDeleteButton = ({ event }) => {
  const [removeEvent] = useMutation(removeEventMutation, {
    variables: {
      eventId: event._id
    },
    refetchQueries: [{ query: eventItemsQuery }]
  })
  const handleClick = () => Modal.confirm({
    title: 'Remove Event',
    content: `confirm ${event.title} removal`,
    onOk: () => removeEvent()
  })
  return <Button icon={<DeleteOutlined />} onClick={handleClick} />
}

export default EventDeleteButton
