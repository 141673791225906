import React from 'react'
// import EventForm from './EventForm'
import EventFormNew from './EventFormNew'
import { message } from 'antd'
import { inject } from 'mobx-react'
import moment from 'moment'
import { useQuery, useMutation } from '@apollo/client'
import createEventMutation from '~/graphql/mutations/createEvent.gql'
import currentAdminUserQuery from '~/graphql/queries/currentAdminUser.gql'
import compose from 'recompose/compose'

const EventCreate = ({ user, router }) => {
  const { loading } = useQuery(currentAdminUserQuery)
  const [createEvent] = useMutation(createEventMutation)
  if (loading) {
    return 'Loading...'
  }
  const handleSubmit = async (values) => {
    // TODO: try catch
    const { date, record } = values
    record.approved = null
    record.createdByUserId = user._id
    record.hidden = false
    record.officialPartner = false
    record.organizerShareUnit = 'percentage'
    record.photoDownloadMode = 'priced'
    record.photoTagFaces = true
    record.photoTagText = false
    record.raceType = 'road'
    record.registrationMethod = 'manual'
    record.searchableAt = moment(date).startOf('day')
    record.singlePhotoPrice = 80
    record.startDate = moment(date).startOf('day')
    const { data } = await createEvent({
      variables: {
        record,
      },
    })
    message.info(`Event "${data?.createEvent?.record?.title}" created`, 10)
    router.push('/events')
  }

  return <EventFormNew onSubmit={handleSubmit} />
  // return <EventForm onSubmit={handleSubmit} defaultValues={defaultValues} />
}

export default compose(inject('router'), inject('user'))(EventCreate)
