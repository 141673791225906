import { useQuery } from '@apollo/client'
import photographerFinancialQuery from '~/graphql/queries/photographerFinancial.gql'
import organizerFinancialQuery from '~/graphql/queries/organizerFinancial.gql'
import brokerFinancialQuery from '~/graphql/queries/brokerFinancial.gql'

const useTotalBalance = () => {
  const photographerResult = useQuery(photographerFinancialQuery, { fetchPolicy: 'cache-first' })
  const organizerResult = useQuery(organizerFinancialQuery, { fetchPolicy: 'cache-first' })
  const brokerResult = useQuery(brokerFinancialQuery, { fetchPolicy: 'cache-first' })
  if (!photographerResult.data || !organizerResult.data || !brokerResult.data) {
    return null
  }
  return (
    photographerResult.data.photographerFinancial.balance +
    organizerResult.data.organizerFinancial.balance +
    brokerResult.data.brokerFinancial.balance
  )
}

export default useTotalBalance
