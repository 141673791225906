import React from 'react'
import { message } from 'antd'
import CouponForm from './CouponForm'
import { inject } from 'mobx-react'
import { useMutation } from '@apollo/client'
import generateCouponsMutation from '~/graphql/mutations/generateCoupons.gql'
import couponCodesPaginationQuery from '../graphql/queries/couponCodesPagination.gql'
import couponCodeTypesPaginationQuery from '../graphql/queries/couponCodeTypesPagination.gql'

const GenerateCoupons = ({ match, router }) => {
  const [generateCoupons] = useMutation(generateCouponsMutation, {
    refetchQueries: [{ query: couponCodeTypesPaginationQuery }, { query: couponCodesPaginationQuery }],
  })
  const defaultValues = router.location.query.couponTypeId

  const handleSubmit = async (values) => {
    await generateCoupons({
      variables: values,
    })
    message.info(`${values.quantity} Coupons generated`, 10)
    router.push(`/coupons?couponTypeId=${values.couponCodeTypeId}`)
  }

  return <CouponForm defaultValues={defaultValues} onSubmit={handleSubmit} />
}

export default inject('router')(GenerateCoupons)
