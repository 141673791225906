import React from 'react'
import PackageForm from './PackageForm'
import { message } from 'antd'
import { inject } from 'mobx-react'
import { useMutation } from '@apollo/client'
import createPhotoPackageMutation from '~/graphql/mutations/createPhotoPackage.gql'

const PackageCreate = inject('router')(({ router }) => {
  const [createPhotoPackage] = useMutation(createPhotoPackageMutation)
  const handleSubmit = async (values) => {
    // TODO: try catch
    const { data } = await createPhotoPackage({
      variables: values
    })
    message.info(`Package "${data?.createPhotoPackage?.record?.name}" created`, 10)
    router.push('/packages')
  }
  const defaultValues = {
    record: {}
  }
  return <PackageForm onSubmit={handleSubmit} defaultValues={defaultValues} />
})

export default PackageCreate
