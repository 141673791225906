import { useMutation } from '@apollo/client'
import { Button, Form, message } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import EventDocumentForm from '../components/EventDocumentForm'
import EventGeneralForm from '../components/EventGeneralForm'
import EventPackageForm from '../components/EventPackageForm'
import EventPhotographerForm from '../components/EventPhotographerForm'
import updateEventMutation from '../graphql/mutations/updateEvent.gql'
import createPhotoPackageForEventMutation from '../graphql/mutations/createPhotoPackageForEvent.gql'
import { compose } from 'recompose'
import { inject } from 'mobx-react'

const gridCenter = { alignContent: 'center', alignItems: 'center', display: 'grid', justifyContent: 'center' }
const animation = `
  .dropdown {
    animation: animate 300ms ease-in-out forwards;
  }
  .hide {
    display: none;
  }
  @keyframes animate {
    0% {
      transform: translateY(-50px);
    }
    60% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(0);
    }
  }
`
const tabNext = {
  general: 'photographer',
  photographer: 'package',
  package: 'document',
}

const EventEditForm = compose(
  inject('router'),
  inject('user')
)(({ defaultValues, eventId, router, tab = 'general', user }) => {
  const { record } = defaultValues
  const { singlePhotoPrice, title } = record ?? {}
  const [form] = Form.useForm()
  const intl = useIntl()
  const [loading, setLoading] = useState()
  const [updateEvent] = useMutation(updateEventMutation)
  const [createPhotoPackageForEvent] = useMutation(createPhotoPackageForEventMutation)
  // const handleClick = (value) => {
  //   router.push(`/events/${eventId}/edit/${value}`)
  // }
  const handleBack = () => {
    router.history.go(-1)
  }
  const handleFormSubmit = async (values) => {
    setLoading(true)
    const { packages, record: _record } = values
    const { startDate, ...record } = _record
    if (tab === 'package') {
      const packageKeys = Object.keys(packages).sort()
      const packageVariables = packageKeys.reduce((prev, curr) => {
        const [type, number] = curr.split('-')
        if (type !== 'unlimitedPackage' && prev[Number(number)]) {
          return prev
        }
        const amount = Number(packages[`${type}-${number}-amount`])
        const minItemCount = packages[`${type}-${number}-minItemCount`] ?? 99
        const packageType = type === 'normalPackage' ? 'discount_fixed' : 'price_fixed'
        const photoSize = 'large'
        const name = `${packageType === 'price_fixed' ? `Unlimited Package` : `Package ${minItemCount}+`} ${photoSize} photos ${amount}${
          packageType === 'discount_percentage' ? '%' : ''
        } for ${singlePhotoPrice}/photo`
        return [...prev, { amount, isDefault: false, minItemCount, name, packageType, photoSize, singlePhotoPrice }]
      }, [])
      const { error: packageError } = await createPhotoPackageForEvent({
        variables: {
          eventId,
          photoPackages: packageVariables,
        },
      })
      if (packageError) {
        message.error(packageError.message, 5)
        setLoading(false)
        return
      }
      message.info(`Event "${title}" updated`, 10)
    } else {
      const { data } = await updateEvent({
        variables: {
          _id: eventId,
          record: {
            ...record,
            ...(startDate && { startDate: moment(startDate).startOf('day') }),
          },
        },
      })
      const { updateEvent: { record: { title } = {} } = {} } = data ?? {}
      message.info(`Event "${title}" updated`, 10)
    }
    if (tab === 'document') {
      router.push('/events')
      setLoading(false)
      return
    }
    router.push(`/events/${eventId}/edit/${tabNext[tab]}`)
    return setLoading(false)
  }
  return (
    <div style={{ display: 'grid', justifyItems: 'center', width: '100%' }}>
      <style>{animation}</style>
      <Form
        form={form}
        layout='vertical'
        className='nonPrintDiv'
        onFinish={handleFormSubmit}
        scrollToFirstError={{ behavior: 'smooth' }}
        style={{ margin: '40px 28px', maxWidth: '800px', width: '100%' }}
      >
        <div
          style={{
            display: 'grid',
            background: 'rgba(245, 245, 245, 1)',
            border: 'none',
            borderRadius: '8px',
            gap: '10px',
            padding: '40px',
          }}
        >
          <div style={{ fontSize: '24px', margin: '0 0 10px 0' }}>{title}</div>
          <div style={{ borderRadius: '12px', display: 'grid' }}>
            <div
              style={{
                ...gridCenter,
                background: '#F4EBFF',
                border: '1px solid #9E77ED',
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                fontWeight: '500',
                gridTemplateColumns: '2fr auto 8fr auto',
                justifyContent: 'start',
                padding: '12px',
                whiteSpace: 'break-spaces',
                ...(tab === 'general' && { borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }),
              }}
            >
              <div>{intl.formatMessage({ id: 'app.generalInformation', defaultMessage: 'General Information' })}</div>
              <div style={{ backgroundColor: 'rgba(17, 17, 17, 0.2)', height: '100%', margin: '0 20px', width: '1.6px' }} />
              <div style={{ color: '#565656', fontSize: '0.8em', fontWeight: '300' }}>
                {intl.formatMessage({ id: 'app.generalInformation', defaultMessage: 'General Information' })}
              </div>
              <div style={{ ...gridCenter, ...(tab === 'general' && { transform: 'rotate(90deg)' }) }}>&#10095;</div>
            </div>
            <EventGeneralForm defaultValues={defaultValues} tab={tab} />
          </div>
          <div style={{ borderRadius: '12px', display: 'grid' }}>
            <div
              style={{
                ...gridCenter,
                background: '#F4EBFF',
                border: '1px solid #9E77ED',
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                fontWeight: '500',
                gridTemplateColumns: '2fr auto 8fr auto',
                justifyContent: 'start',
                padding: '12px',
                whiteSpace: 'break-spaces',
                ...(tab === 'photographer' && { borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }),
              }}
            >
              <div>{intl.formatMessage({ id: 'app.photographer', defaultMessage: 'Photographer' })}</div>
              <div style={{ backgroundColor: 'rgba(17, 17, 17, 0.2)', height: '100%', margin: '0 20px', width: '1.6px' }} />
              <div style={{ color: '#565656', fontSize: '0.8em', fontWeight: '300' }}>
                {intl.formatMessage({ id: 'app.photographer', defaultMessage: 'Photographer' })}
              </div>
              <div style={{ ...gridCenter, ...(tab === 'photographer' && { transform: 'rotate(90deg)' }) }}>&#10095;</div>
            </div>
            <EventPhotographerForm defaultValues={defaultValues} form={form} tab={tab} />
          </div>
          <div style={{ borderRadius: '12px', display: 'grid' }}>
            <div
              style={{
                ...gridCenter,
                background: '#F4EBFF',
                border: '1px solid #9E77ED',
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                fontWeight: '500',
                gridTemplateColumns: '2fr auto 8fr auto',
                justifyContent: 'start',
                padding: '12px',
                whiteSpace: 'break-spaces',
                ...(tab === 'package' && { borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }),
              }}
            >
              <div>{intl.formatMessage({ id: 'app.package', defaultMessage: 'Package' })}</div>
              <div style={{ backgroundColor: 'rgba(17, 17, 17, 0.2)', height: '100%', margin: '0 20px', width: '1.6px' }} />
              <div style={{ color: '#565656', fontSize: '0.8em', fontWeight: '300' }}>
                {intl.formatMessage({ id: 'app.package', defaultMessage: 'Package' })}
              </div>
              <div style={{ ...gridCenter, ...(tab === 'package' && { transform: 'rotate(90deg)' }) }}>&#10095;</div>
            </div>
            <EventPackageForm defaultValues={defaultValues} form={form} tab={tab} />
          </div>
          <div style={{ borderRadius: '12px', display: 'grid' }}>
            <div
              style={{
                ...gridCenter,
                background: '#FFEBFE',
                border: '1px solid #ED77C4',
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
                fontWeight: '500',
                gridTemplateColumns: '2fr auto 8fr auto',
                justifyContent: 'start',
                padding: '12px',
                whiteSpace: 'break-spaces',
                ...(tab === 'document' && { borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }),
              }}
            >
              <div>{intl.formatMessage({ id: 'app.additionalDocuments', defaultMessage: 'Additional Documents' })}</div>
              <div style={{ backgroundColor: 'rgba(17, 17, 17, 0.2)', height: '100%', margin: '0 20px', width: '1.6px' }} />
              <div style={{ color: '#565656', fontSize: '0.8em', fontWeight: '300' }}>
                {intl.formatMessage({ id: 'app.additionalDocuments', defaultMessage: 'Additional Documents' })}
              </div>
              <div style={{ ...gridCenter, ...(tab === 'document' && { transform: 'rotate(90deg)' }) }}>&#10095;</div>
            </div>
            <EventDocumentForm defaultValues={defaultValues} tab={tab} />
          </div>
          <div
            style={{
              display: 'grid',
              justifyContent: 'end',
              margin: '10px 0 0 0',
              ...(tab !== 'general' && { gap: '0 10px', gridTemplateColumns: 'auto auto' }),
            }}
          >
            {tab !== 'general' && (
              <Button disabled={loading} loading={loading} onClick={handleBack} style={{ background: 'lightgrey', border: 'none', color: 'grey' }}>
                {intl.formatMessage({ id: 'app.back', defaultMessage: 'Back' })}
              </Button>
            )}
            <Button disabled={loading} loading={loading} htmlType='submit' style={{ background: '#BD188B', border: 'none', color: 'white' }}>
              {intl.formatMessage({ id: 'app.next', defaultMessage: 'Next' })}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
})

export { EventEditForm as default }
