import React from 'react'
import { useQuery } from '@apollo/client'
import { Spin } from 'antd'
import salespersonFinancialQuery from '~/graphql/queries/salespersonFinancial.gql'
import FinancialInfo from './Financialnfo'

const SalespersonInfo = ({ salespersonId }) => {
  const { error, loading, data } = useQuery(salespersonFinancialQuery, {
    variables: {
      _id: salespersonId
    }
  })
  if (loading) {
    return <Spin />
  }
  if (error) {
    return 'Error...'
  }
  const { share, transfer, balance } = data?.salespersonFinancial ?? {}
  return <FinancialInfo share={share} transfer={transfer} balance={balance} />
}

export default SalespersonInfo
