import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Col, Row, Popover } from 'antd'
import axios from 'axios'
import loadImage from 'blueimp-load-image'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import CloudImageUploader from './CloudImageUploaderNew'
import cloudUploadMutation from '../graphql/mutations/cloudUpload.gql'

const FrameImageInput = React.forwardRef(({ children, portraitPreviewUrl, landscapePreviewUrl, onChange, value = {} }, ref) => {
  console.log({ portraitPreviewUrl, landscapePreviewUrl })
  const intl = useIntl()
  const [upload] = useMutation(cloudUploadMutation)
  const [loading, setLoading] = useState(false)
  const { current: _value } = useRef(value)
  const { framePortraitUrl, frameLandscapeUrl } = _value
  // const { getFieldDecorator, setFieldsValue } = form
  // const framePortraitUrl = getFieldValue('framePortraitUrl')
  // const frameLandscapeUrl = getFieldValue('frameLandscapeUrl')
  const onChangeFn = (field) => (v) => {
    console.log('changed', field)
    _value[field] = v
    onChange(_value)
  }
  const fileMiddleware = async (file, next) => {
    setLoading(true)
    next(file) // TODO: Promise.all?
    const { originalWidth, originalHeight } = await loadImage(file, {
      orientation: true,
    })
    if (originalWidth >= 4800 || originalHeight >= 4800) {
      return file
    }
    const { image } = await loadImage(file, {
      orientation: true,
      canvas: true,
    })
    const canvas = document.createElement('CANVAS')
    canvas.width = 7200
    canvas.height = 4800
    canvas.getContext('2d').drawImage(image, 0, 0)
    const blob = await new Promise((resolve) => canvas.toBlob(resolve, file.type))

    const response = await upload({
      variables: {
        provider: 'gs',
        mimeType: file.type,
        isTemporary: true,
      },
    })
    const { uploadUrl, downloadUrl } = response?.data?.upload ?? {}
    if (uploadUrl) {
      await axios.put(uploadUrl, blob, {
        headers: {
          'Content-Type': file.type,
          'x-goog-acl': 'public-read',
        },
      })
      onChangeFn('frameLandscapeUrl')(downloadUrl)
    }
  }

  return (
    <Row gutter={[12, 0]}>
      <Col xs={24} sm={12}>
        <div style={{ marginBottom: '15px' }}>
          {intl.formatMessage({ id: 'app.vertical', defaultMessage: 'Vertical' })}
          <Popover placement='right' content={<div style={{ width: 300 }}>{intl.formatMessage({ id: 'app.photoFrameInfo' })}</div>}>
            &nbsp;
            <ExclamationCircleOutlined />
          </Popover>
        </div>
        <CloudImageUploader
          placeholderUrl={portraitPreviewUrl}
          fileMiddleware={fileMiddleware}
          onChange={onChangeFn('framePortraitUrl')}
          value={framePortraitUrl}
          loading={loading}
          resize={{
            width: 4800,
            height: 7200,
          }}
          accept='image/png'
          isTemporary
        >
          {children}
        </CloudImageUploader>
      </Col>
      <Col xs={24} sm={12}>
        <div style={{ marginBottom: '15px' }}>
          {intl.formatMessage({ id: 'app.horizontal', defaultMessage: 'Horizontal' })}
          <Popover placement='right' content={<div style={{ width: 300 }}>{intl.formatMessage({ id: 'app.photoFrameInfo' })}</div>}>
            &nbsp;
            <ExclamationCircleOutlined />
          </Popover>
        </div>
        <CloudImageUploader
          placeholderUrl={landscapePreviewUrl}
          fileMiddleware={fileMiddleware}
          onChange={onChangeFn('frameLandscapeUrl')}
          value={frameLandscapeUrl}
          loading={loading}
          resize={{
            width: 7200,
            height: 4800,
          }}
          accept='image/png'
          isTemporary
        >
          {children}
        </CloudImageUploader>
      </Col>
    </Row>
  )
})

export default FrameImageInput
