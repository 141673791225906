import React, { forwardRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import eventsQuery from '../graphql/queries/events.gql'

const EventSelect = forwardRef(({ onChange, value, variables: { creditTargets } = {}, myEvent, style }, ref) => {
  const [search, setSearch] = useState('')
  const { error, loading, data } = useQuery(eventsQuery, {
    variables: {
      search,
      myEvent,
      ...(creditTargets ? { creditTargets } : {}),
      limit: 10,
    },
  })
  if (error) {
    return 'Error'
  }
  return (
    <Select
      loading={loading}
      allowClear
      showSearch
      filterOption={false}
      placeholder={<FormattedMessage id='app.selectEvent' defaultMessage='Select Event...' />}
      onChange={onChange}
      onSearch={(v) => setSearch(v)}
      onBlur={() => setSearch()}
      value={value}
      maxTagCount={6}
      style={{ width: 200, ...style }}
    >
      {data?.events?.map((item) => (
        <Select.Option key={item._id} value={item._id}>
          {item.title}
        </Select.Option>
      ))}
    </Select>
  )
})

export default EventSelect
