import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import qbFindVendorsQuery from '~/graphql/queries/qbFindVendors.gql'
import qbAdminUserVendorQuery from '~/graphql/queries/qbAdminUserVendor.gql'
import qbSetAdminUserVendorMutation from '~/graphql/mutations/qbSetAdminUserVendor.gql'
import { Select, Spin } from 'antd'

const QbAdminUserVendor = ({ adminUserId }) => {
  const { error, loading, data } = useQuery(qbFindVendorsQuery)
  const { data: qbAdminUserData } = useQuery(qbAdminUserVendorQuery, {
    variables: {
      adminUserId,
    },
  })
  const [qbSetAdminUserVendor] = useMutation(qbSetAdminUserVendorMutation)
  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ display: 'block' }} />
  }
  const value = qbAdminUserData?.qbAdminUserVendor?.qbId
  const handleChange = async (qbId) => {
    console.log('change', qbId)
    const vendor = data?.qbFindVendors?.find((vendor) => vendor.value === qbId)
    const qbName = vendor?.name
    await qbSetAdminUserVendor({
      variables: {
        adminUserId,
        qbId,
        qbName,
      },
    })
  }
  return (
    <Select
      showSearch
      onChange={handleChange}
      defaultValue={value}
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      style={{ width: '100%' }}
    >
      {data?.qbFindVendors?.map((vendor) => {
        return (
          <Select.Option key={vendor.value} value={vendor.value}>
            {vendor.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}

export default QbAdminUserVendor
