import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Button, Avatar, Row, Col, Tag } from 'antd'
import { EditOutlined, UserOutlined } from '@ant-design/icons'
import _get from 'lodash/get'
import './ViewProfile.css'

import currentAdminUserQuery from '../graphql/queries/currentAdminUser.gql'

@graphql(currentAdminUserQuery, {
  options: { fetchPolicy: 'network-only' }
})
class ViewProfile extends Component {
  // static propTypes = {
  //     data: React.PropTypes.shape({
  //         loading: React.PropTypes.bool,
  //         error: React.PropTypes.object,
  //         currentUser: React.PropTypes.object,
  //         logicFile: React.PropTypes.object,
  //     }).isRequired,
  // }

  render () {
    const { currentAdminUser: currentUser } = this.props.data

    // const avatarStyle = {
    //     width: '200px',
    //     height: '200px',
    //     marginLeft: '40%',
    //     marginBottom: '1em',
    // }

    if (this.props.data.loading) {
      return (<div style={{textAlign: 'center'}}>Loading</div>)
    }
    // console.log('url', currentUser.logoUrl)
    // if (this.props.data.error) {
    //     console.log(this.props.data.error)
    //     return (<div>An unexpected error occurred</div>)
    // }

    // if (!currentUser) {
    //     return <div style={{textAlign:'center'}}>Loading</div>
    // }
    const labelProps = {
      xs: { span: 24 },
      sm: { span: 6 },
      style: { textAlign: 'right', fontWeight: 'bold' }
    }
    const valueProps = {
      xs: { span: 24 },
      sm: { span: 6 }
    }
    const profileImageUrl = _get(currentUser, 'photographerProfile.profileImageUrl')
    return (
      <div>
        {/* <img src="https://media.licdn.com/mpr/mpr/shrink_200_200/AAEAAQAAAAAAAAjRAAAAJDE4ZjdhZTlkLTQ2ODUtNDVmYS1iNjgyLTMwMjA1YTk3MGFhMQ.png" style={avatarStyle} alt="" /> */}
        <div style={{textAlign: 'center', marginBottom: '1em'}}>
          {profileImageUrl ? <img src={profileImageUrl} style={{width: '200px', height: '200px'}} alt="" /> : <Avatar className="ant-avatar-lg_big" icon={<UserOutlined />} type="circle" size="large"/>}
        </div>
        {currentUser.role === 'photographer' && (
        <Row type="flex" justify="center" gutter={16}>
          <Col {...labelProps} >
            <FormattedMessage id="app.photographerIdNumber" defaultMessage="Photographer Number" />
          </Col>
            <Col {...valueProps} ><Tag color="blue">TR{`${currentUser.number}`.padStart(4, 0)}</Tag></Col>
        </Row>
        )}
        <Row type="flex" justify="center" gutter={16}>
          <Col {...labelProps} >
            <FormattedMessage id="app.citizenID" defaultMessage="Citizen ID"/>
          </Col>
          <Col {...valueProps} >{_get(currentUser, 'profile.nationalID')}</Col>
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Col {...labelProps} >
            <FormattedMessage id="app.firstName" defaultMessage="First Name"/>
          </Col>
          <Col {...valueProps} >{_get(currentUser, 'profile.firstName')}</Col>
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Col {...labelProps} >
            <FormattedMessage id="app.lastName" defaultMessage="Last Name"/>
          </Col>
          <Col {...valueProps} >{_get(currentUser, 'profile.lastName')}</Col>
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Col {...labelProps} >
            <FormattedMessage id="app.displayName" defaultMessage="Display Name"/>
          </Col>
          <Col {...valueProps} >{currentUser.displayName}</Col>
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Col {...labelProps} >
            <FormattedMessage id="app.email" defaultMessage="Email"/>
          </Col>
          <Col {...valueProps} >{_get(currentUser, 'profile.email')}</Col>
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Col {...labelProps} >
            <FormattedMessage id="app.phone" defaultMessage="Phone"/>
          </Col>
          <Col {...valueProps} >{_get(currentUser, 'profile.phone')}</Col>
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Col {...labelProps} >
            <FormattedMessage id="app.username" defaultMessage="Username"/>
          </Col>
          <Col {...valueProps} >{currentUser.username}</Col>
        </Row>
        <Button type="submit" style={{float: 'right'}} >
          <Link to="/profile/edit">
            <FormattedMessage id="app.editProfile" defaultMessage="Edit Profile"/>
            <EditOutlined />
          </Link>
        </Button>
      </div>
    )
  }
}

// const ViewUserWithQuery = graphql(ViewCurrentUserQuery, {
//   options: (ownProps) => ({
//     variables: {
//       _id: ownProps.
//     }
//   })
// })(ViewProfile)

export default ViewProfile
