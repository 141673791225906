import React from 'react'
import { useLocalStore } from 'mobx-react-lite'
import { RouterStore, UserStore, I18NStore } from '../stores'

const storesContext = React.createContext(null)

// const storeContext = React.createContext(null)

const createStore = () => ({
  routerStore,
  userStore,
  i18nStore
})
export const i18nStore = new I18NStore()
export const userStore = new UserStore()
export const routerStore = new RouterStore()
export const StoresProvider = ({ children }) => {
  const store = useLocalStore(createStore)
  return <storesContext.Provider value={store}>{children}</storesContext.Provider>
}

export const useStores = () => {
  const store = React.useContext(storesContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}

// export default storesContext
