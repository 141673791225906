import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Select } from 'antd'

const raceTypeInput = [
  { _id: 0, method: 'all', name: <FormattedMessage id='app.allRaceType' defaultMessage='All Race Type' /> },
  { _id: 1, method:'road', name: <FormattedMessage id='app.road' defaultMessage='Road' /> },
  { _id: 2, method:'trail', name: <FormattedMessage id='app.trail' defaultMessage='Trail' /> },
  { _id: 3, method:'triathlon', name: <FormattedMessage id='app.triathlon' defaultMessage='Triathlon' /> },
]


const RaceTypeSelect = ({ style, onChange, value }) => {
 
  return (
    <Select
      allowClear
      // showSearch
      filterOption={false}
      placeholder={ <FormattedMessage id='app.allRaceType' defaultMessage='All Race Type' />}
      onChange={onChange}
      // onSearch={(v) => setSearch(v)}
      // onBlur={() => setSearch()}
      value={value ?? 'all'}
      // maxTagCount={6}
      style={{ width: 200, ...style }}
    >
      {raceTypeInput.map((item) => (
        <Select.Option key={item._id} value={item.method}>
          {item.name}
        </Select.Option>
        ))}
    </Select>
  )
}

export default RaceTypeSelect
