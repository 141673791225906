import React from 'react'
import compose from 'recompose/compose'
import { inject, observer } from 'mobx-react'
import { ConfigProvider } from 'antd'
import en from 'antd/lib/locale-provider/en_US'
import th from 'antd/lib/locale-provider/th_TH'
import moment from 'moment'
import 'moment/locale/th'

import { IntlProvider } from 'react-intl'
// import enLocaleData from 'react-intl/locale-data/en'
// import thLocaleData from 'react-intl/locale-data/th'

import enMessages from '~/i18n/en.json'
import thMessages from '~/i18n/th.json'

const locales = {
  en,
  th
}

// addLocaleData(enLocaleData)
// addLocaleData(thLocaleData)

const messages = {
  en: enMessages,
  th: thMessages
}

const ConnectedLocaleProvider = compose(
  inject('i18n'),
  observer
)(({ children, i18n: { locale } }) => ((
  moment.locale(locale),
  <ConfigProvider locale={locales[locale]}>
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  </ConfigProvider>
)))

export default ConnectedLocaleProvider
