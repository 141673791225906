import React from 'react'
import { Typography, Modal, Spin } from 'antd'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import Linkify from 'react-linkify'

import announcementQuery from '~/graphql/queries/announcement.gql'
const Announcement = ({ id }) => {
  const { error, loading, data } = useQuery(announcementQuery, {
    variables: { _id: id }
  })
  const history = useHistory()
  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin />
  }
  const { announcement } = data
  return (
    <Modal
      open
      closable
      title={announcement.title}
      footer={null}
      onCancel={() => history.push('/announcements')}
      bodyStyle={{ whiteSpace: 'pre-line' }}
    >
      <Typography.Text type="secondary">
        {moment(announcement.createdAt).format('LLL')}
      </Typography.Text>
      <Typography.Paragraph>
        <Linkify componentDecorator={(href, text, key) => (
          <a href={href} key={key} style={{ textDecoration: 'underline' }}>
            {text}
          </a>
        )}>
          {announcement.message}
        </Linkify>
      </Typography.Paragraph>
    </Modal>
  )
}

export default Announcement
