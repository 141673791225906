import React, { Component } from 'react'
import Dropzone from 'react-fine-uploader/dropzone'
import FileInput from 'react-fine-uploader/file-input'
import Thumbnail from 'react-fine-uploader/thumbnail'
import Filename from 'react-fine-uploader/filename'

import { Tabs, Badge, Button, Popover, Progress, Row, Col, message } from 'antd'
import { CloseCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import filesize from 'filesize'

import update from 'immutability-helper'
import _values from 'lodash/values'

class ThairunUploader extends Component {
  constructor () {
    super()
    this.state = {
      statuses: [],
      files: {},
      total: {}
    }
  }
  componentDidMount () {
    const { uploader } = this.props
    const {
      QUEUED, UPLOADING, PAUSED,
      UPLOAD_SUCCESSFUL, UPLOAD_FAILED
      // UPLOAD_RETRYING
    } = uploader.qq.status
    this.setState({
      statuses: [
        QUEUED, UPLOADING, PAUSED,
        UPLOAD_SUCCESSFUL, UPLOAD_FAILED
        // UPLOAD_RETRYING
      ]
    })
    uploader.on('submitted', (id, name) => {
      const files = update(this.state.files, {
        [id]: { $merge: { id, name } }
      })
      this.setState({ files })
    })
    uploader.on('statusChange', (id, oldStatus, newStatus) => {
      const files = update(this.state.files, {
        $merge: {
          [id]: { id, status: newStatus }
        }
      })
      this.setState({ files })
    })
    uploader.on('progress', (id, name, bytesUploaded, bytesTotal) => {
      const files = update(this.state.files, {
        [id]: {
          $merge: { bytesUploaded, bytesTotal }
        }
      })
      this.setState({ files })
    })
    uploader.on('totalProgress', (bytesUploaded, bytesTotal) => {
      this.setState({ total: { bytesUploaded, bytesTotal } })
    })
    uploader.on('error', (id, name, errorReason, xhrOrXdr) => {
      message.error(errorReason)
    })
  }
  continueAll = (files) => {
    // const { continueUpload } = this.props.uploader.methods
    files.forEach(file => this.props.uploader.methods.continueUpload(file.id))
  }
  pauseAll = (files) => {
    // const { pauseUpload } = this.props.uploader.methods
    files.forEach(file => this.props.uploader.methods.pauseUpload(file.id))
  }
  retryAll = (files) => {
    // const { retry } = this.props.uploader.methods
    files.forEach(file => this.props.uploader.methods.retry(file.id))
  }
  render () {
    const { uploader } = this.props
    // console.log('u', uploader.methods.getUploads)
    const { UPLOADING, UPLOAD_FAILED, PAUSED, UPLOAD_SUCCESSFUL } = uploader.qq.status
    const { bytesUploaded, bytesTotal } = this.state.total
    // const { pauseUpload, continueUpload, retry, cancel } = uploader.methods
    const allFiles = _values(this.state.files).filter(f => this.state.statuses.includes(f.status))
    return <div style={{ marginTop: '1em' }}>
      <Dropzone
        style={{
          height: '150px',
          width: '100%',
          background: 'gainsboro',
          borderRadius: '4px',
          border: '1px dashed grey',
          textAlign: 'center',
          lineHeight: '150px'
        }}
        multiple
        uploader={uploader}
      >
        <FileInput multiple accept='image/*' uploader={uploader}>
          <span className="icon ion-upload">Drop your photos here</span>
        </FileInput>
      </Dropzone>
      {/* <p>ตอนนี้ระบบอัปโหลดภาพขัดข้องชั่วคราว ทีมงานกำลังเร่งแก้ไข</p> */}
      {allFiles.length > 0 && <Progress type='line' percent={bytesUploaded / bytesTotal * 100 || 0} status='active' format={p => `${p.toFixed(2)}%`} showInfo />}
      {allFiles.length > 0 &&
      <Tabs defaultActiveKey={UPLOADING}>
        {this.state.statuses.map(status => {
          const files = uploader.methods.getUploads({ status })
          // const files = allFiles.filter(file => file.status === status)
          const actionButtonMap = {
            [UPLOADING]: <Button icon={<PauseCircleOutlined />} onClick={() => this.pauseAll(files)} >pause all</Button>,
            [PAUSED]: <Button icon={<PlayCircleOutlined />} onClick={() => this.continueAll(files)} >resume all</Button>,
            [UPLOAD_FAILED]: <Button icon={<ReloadOutlined />} onClick={() => this.retryAll(files)} >retry all</Button>
          }
          return (
            <Tabs.TabPane key={status} disabled={files.length === 0} tab={<span>{status} <Badge count={files.length} overflowCount={9999} style={{ background: '#108ee9' }}/></span>}>
              {files.length > 0 && <Row>
                <Col span={24} style={{ textAlign: 'center' }}>{actionButtonMap[status]}</Col>
              </Row>}
              {files.map(file => {
                const { bytesUploaded, bytesTotal } = this.state.files[file.id]
                // const { bytesUploaded, bytesTotal } = file
                const uploadedSize = filesize(bytesUploaded || 0)
                const totalSize = filesize(bytesTotal || 0)
                const percent = bytesUploaded / bytesTotal * 100 || (status === UPLOAD_SUCCESSFUL ? 100 : 0)
                return <Row key={file.id}>
                  <Col span={6}>
                    <Popover content={<Thumbnail uploader={uploader} id={file.id} />}>
                      <Button><Filename uploader={uploader} id={file.id} /></Button>
                    </Popover>
                  </Col>
                  <Col span={14}>
                    <Progress
                      type='line'
                      percent={percent}
                      status={status === UPLOADING ? 'active' : 'normal'}
                      format={p => `${p.toFixed(2)}%`}
                      showInfo
                    />
                    {status !== UPLOAD_SUCCESSFUL && <span>{uploadedSize} / {totalSize}</span>}
                  </Col>
                  <Col span={4} style={{ textAlign: 'right' }}>
                    {status === UPLOADING &&
                      <Button shape='circle' icon={<PauseCircleOutlined />} onClick={() => uploader.methods.pauseUpload(file.id)} />
                    }
                    {status === PAUSED &&
                      <Button shape='circle' icon={<PlayCircleOutlined />} onClick={() => uploader.methods.continueUpload(file.id)} />
                    }
                    {(status === UPLOADING || status === PAUSED) &&
                      <Button shape='circle' icon={<CloseCircleOutlined />} style={{ color: 'red' }} onClick={() => uploader.methods.cancel(file.id)} />
                    }
                    {status === UPLOAD_FAILED &&
                      <Button shape='circle' icon={<ReloadOutlined />} onClick={() => uploader.methods.retry(file.id)} />
                    }
                  </Col>
                </Row>
              })}
            </Tabs.TabPane>
          )
        })}
      </Tabs>
      }
    </div>
  }
}

export default ThairunUploader
