import React from 'react'
import { inject } from 'mobx-react'
import { useMutation, useQuery } from '@apollo/client'
import { message, Spin } from 'antd'
import couponCodeTypeQuery from '../graphql/queries/couponCodeType.gql'
import couponCodeTypesPaginationQuery from '../graphql/queries/couponCodeTypesPagination.gql'
import updateCouponTypeMutation from '../graphql/mutations/updateCouponType.gql'
import CouponTypeForm from './CouponTypeForm'

const CouponTypeUpdate = inject('router')(({ match, router }) => {
  const { couponTypeId } = match.params
  const { error, loading, data } = useQuery(couponCodeTypeQuery, {
    variables: { _id: couponTypeId },
    fetchPolicy: 'network-only'
  })
  const [updateCouponType] = useMutation(updateCouponTypeMutation, {
    refetchQueries: [{ query: couponCodeTypesPaginationQuery }],
  })

  const handleSubmit = async (values) => {
    const { data } = await updateCouponType({
      variables: { _id: couponTypeId, ...values},
    })
    message.info(`Coupon code type "${data?.updateCouponType?.record?.name}" updated`, 10)
    router.push('/coupon-types')
  }

  if (error) {
    return 'error'
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  return <CouponTypeForm defaultValues={data} onSubmit={handleSubmit} />
})

export default CouponTypeUpdate
