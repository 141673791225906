import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Select, Spin } from 'antd'
import qbCouponCustomerQuery from '~/graphql/queries/qbCouponCustomer.gql'
import qbFindCustomersQuery from '~/graphql/queries/qbFindCustomers.gql'
import qbSetCouponCustomerMutation from '~/graphql/mutations/qbSetCouponCustomer.gql'
const QbCoupon = ({ couponCodeTypeId }) => {
  const { error, loading, data } = useQuery(qbFindCustomersQuery)
  const { data: qbCouponCustomerData } = useQuery(qbCouponCustomerQuery, {
    variables: {
      couponCodeTypeId
    }
  })
  const [qbSetCouponCustomer] = useMutation(qbSetCouponCustomerMutation)
  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '10vh' }} />
  }
  const value = qbCouponCustomerData?.qbCouponCustomer?.qbId
  const handleChange = async (qbId) => {
    console.log('change', qbId)
    const customer = data?.qbFindCustomers.find(c => c.value === qbId)
    const qbName = customer?.name
    await qbSetCouponCustomer({ variables: {
      couponCodeTypeId,
      qbId,
      qbName
    }})
  }
  return <Select
    showSearch
    onChange={handleChange}
    defaultValue={value}
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    style={{ width: '100%' }}
  >
    {data?.qbFindCustomers.map(c =>
      <Select.Option key={c.value} value={c.value} >{c.name}</Select.Option>
    )}
  </Select>
}

export default QbCoupon
