import React from 'react'
import { Progress, Row, Col } from 'antd'
import { FormattedNumber, FormattedNumberParts } from 'react-intl'
import config from '../config'

const FinancialInfo = ({ share, transfer, balance }) => <>
  {console.log({ share })}
  <Row>
    <FormattedNumberParts value={share} style="currency" currency={config.currency} currencySign="standard">{/* eslint-disable-line react/style-prop-object */}
      {([currency, ...parts]) => <span style={{ color: '#269afb' }}>
        <span className="currency">{currency.value}</span>
        {' '}
        <span className="number" style={{ fontSize: 'larger' }}>{`${parts.map(p => p.value).join('')}`}</span>
      </span>}
    </FormattedNumberParts>
  </Row>
  <Row>
    <Col span={24}>
      <Progress
        showInfo={false}
        percent={transfer / share * 100}
        strokeLinecap="round"
        strokeWidth={8}
      />
    </Col>
  </Row>
  <Row>
    <Col span={12} style={{ textAlign: 'left' }}>
      paid: <FormattedNumber value={transfer} style="currency" currency={config.currency} currencySign="standard" />{/* eslint-disable-line react/style-prop-object */}
    </Col>
    <Col span={12} style={{ textAlign: 'right' }}>
      unpaid: <FormattedNumber value={balance} style="currency" currency={config.currency} currencySign="standard" />{/* eslint-disable-line react/style-prop-object */}
    </Col>
  </Row>
</>

export default FinancialInfo
