import React from 'react'
import { useQuery } from '@apollo/client'
// import organizerQuery from '~/graphql/queries/organizer.gql'
import organizerFinancialQuery from '~/graphql/queries/organizerFinancial.gql'
import { Spin } from 'antd'
import FinancialInfo from './Financialnfo'

const OrganizerInfo = ({ organizerId }) => {
  const { error, loading, data } = useQuery(organizerFinancialQuery, {
    variables: {
      _id: organizerId
    }
  })
  if (loading) {
    return <Spin />
  }
  if (error) {
    return 'Error...'
  }

  const { share, transfer, balance } = data?.organizerFinancial ?? {}
  return <FinancialInfo share={share} transfer={transfer} balance={balance} />
}

export default OrganizerInfo
