import React, { useEffect } from 'react'
import { FormattedNumber, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { inject } from 'mobx-react'
import { useQuery } from '@apollo/client'
import { Tag, Table, Button, Select, Spin, DatePicker } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { compose } from 'recompose'
import moment from 'moment-timezone'
import _isEmpty from 'lodash/isEmpty'
import _omitBy from 'lodash/omitBy'
import transferRequestsQuery from '../graphql/queries/transferRequests.gql'
import TransferRequestDetailsModal from './TransferRequestDetailsModal'
import TransferRequestReviewModal from './TransfrerRequestReviewModal'
import Toolbar from './Toolbar'
import AdminUserSelect from './AdminUserSelect'

const tagColors = {
  pending: 'gold',
  approved: 'blue',
  cancelled: 'volcano',
  rejected: 'red',
  completed: 'green',
}

const TransferRequestList = compose(
  inject('user'),
  inject('router')
)(({ router, user }) => {
  const { location } = router
  const { from: startDate, p: page, reviewTrId, status, to: endDate, trId, userId } = location.query
  const authorized = ['superadmin', 'admin'].includes(user.role)
  const { error, loading, data } = useQuery(transferRequestsQuery, {
    variables: {
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).endOf('day').toISOString(),
      page,
      ...(status ? { status } : {}),
      ...(userId === 'all' ? {} : { userId }),
    },
    skip: !startDate && !endDate,
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    if (!location.query.from && !location.query.to) {
      location.query = {
        ...location.query,
        from: moment().subtract(3, 'months').format(moment.HTML5_FMT.DATE),
        to: moment().format(moment.HTML5_FMT.DATE),
      }
    }
  }, [location.query.from, location.query.to, location.query])

  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }

  const handleStatusChange = (status) => {
    location.query = _omitBy(
      {
        ...location.query,
        status,
      },
      _isEmpty
    )
  }
  const handleRequestReviewOpen = (reviewTrId) => {
    location.query = {
      ...location.query,
      reviewTrId,
    }
  }
  const handleRequestReviewClose = () => {
    const { reviewTrId, ...query } = location.query
    location.query = query
  }
  const handleRequestDetailsOpen = (trId) => {
    location.query = {
      ...location.query,
      trId,
    }
  }
  const handleRequestDetailsClose = () => {
    const { trId, ...query } = location.query
    location.query = query
  }
  const handleAdminUserSelect = (userId) => {
    location.query = {
      ...location.query,
      userId,
    }
  }
  const handlePageChange = (page) => {
    location.query = {
      ...location.query,
      p: page,
    }
  }
  const handleDateChange = ([$startDate, $endDate]) => {
    const { p, ...query } = location.query
    location.query = {
      ...query,
      ...($startDate &&
        $endDate && {
          from: $startDate.format(moment.HTML5_FMT.DATE),
          to: $endDate.format(moment.HTML5_FMT.DATE),
        }),
    }
  }

  const statuses = Object.keys(tagColors)
  const {
    items: transferRequests,
    pageInfo,
  } = data?.transferRequestPagination ?? {}
  const { currentPage, itemCount, perPage } = pageInfo ?? {}
  return (
    <div>
      <Toolbar
        left={
          <div>
            {authorized && <AdminUserSelect onChange={handleAdminUserSelect} value={userId} role={'photographer'} selectAll style={{ width: 200 }} />}
            <Select defaultValue={status || ''} onChange={handleStatusChange} style={{ width: 150 }}>
              <Select.Option key='all' value=''>
                <FormattedMessage id='app.allStatuses' defaultMessage='All Statuses' />
              </Select.Option>
              {statuses?.map((status) => (
                <Select.Option key={status} value={status}>
                  <FormattedMessage id={`app.status.transferRequest.${status}`} />
                </Select.Option>
              ))}
            </Select>
            <DatePicker.RangePicker
              defaultValue={(startDate && endDate) ? [moment(startDate), moment(endDate)] : []}
              format='ll'
              onChange={handleDateChange}
              timePicker={false}
              value={(startDate && endDate) ? [moment(startDate), moment(endDate)] : []}
            />
          </div>
        }
      >
        <Link to='/transfers/create'>
          <Button>
            <FormattedMessage id='app.createWithdrawalRequest' defaultMessage='Create Withdrawal Requests' />
          </Button>
        </Link>
      </Toolbar>
      {!!reviewTrId && <TransferRequestReviewModal id={reviewTrId} onClose={handleRequestReviewClose} />}
      {!!trId && <TransferRequestDetailsModal id={trId} onClose={handleRequestDetailsClose} />}
      <Table
        dataSource={transferRequests}
        rowKey='_id'
        onRow={(req) => ({
          onClick: () => handleRequestDetailsOpen(req._id),
        })}
        pagination={{
          defaultPageSize: perPage,
          showQuickJumper: true,
          defaultCurrent: 1,
          current: currentPage,
          total: itemCount,
          // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          onChange: handlePageChange,
        }}
      >
        {authorized && (
          <Table.Column
            title={<FormattedMessage id='app.photographer' defaultMessage='Photographer' />}
            dataIndex={['user', 'displayName']}
            key='photographer'
          />
        )}
        <Table.Column
          title={<FormattedMessage id='app.requestDate' defaultMessage='Request Date' />}
          dataIndex={['dateRequested']}
          key='dateRequested'
          width={200}
          render={(dateRequested) => 
            <a onClick={(e) => e.preventDefault()}>{moment(dateRequested).format('Do MMM YYYY, HH:mm:ss')}</a> // eslint-disable-line jsx-a11y/anchor-is-valid
          }
        />
        <Table.Column
          title={<FormattedMessage id='app.amountRequested' defaultMessage='Withdrawal Amount' />}
          dataIndex={['amount']}
          key='amount'
          align='right'
          width={200}
          render={(amount) => <FormattedNumber value={amount} minimumFractionDigits={2} />}
        />
        <Table.Column title={<FormattedMessage id='app.note' defaultMessage='Note' />} dataIndex={['note']} key='note' />
        {authorized && (
          <Table.Column
            title={<FormattedMessage id='app.reviewDate' defaultMessage='Review Date' />}
            dataIndex={['dateApproved']}
            key='dateApproved'
            width={200}
            render={(dateApproved) => dateApproved && moment(dateApproved).format('Do MMM YYYY, HH:mm:ss')}
          />
        )}
        <Table.Column
          title={<FormattedMessage id='app.status' defaultMessage='Status' />}
          dataIndex={['status']}
          key='status'
          render={(status) => (
            <Tag color={tagColors[status]}>
              <FormattedMessage id={`app.status.transferRequest.${status}`} />
            </Tag>
          )}
        />
        {authorized && (
          <Table.Column
            title='Review'
            key='review'
            dataIndex={['status']}
            render={(status, { _id }) =>
              status === 'pending' && <Button icon={<CheckCircleOutlined />} onClick={(e) => e.stopPropagation() || handleRequestReviewOpen(_id)} />
            }
          />
        )}
      </Table>
    </div>
  )
})

// <div className="balance-group">
// <Row type="flex" justify="center" align="middle" >
//   <Col xs={24} sm={2}>
//       Total Revenue
//     <br/>
//     <Avatar style={{ backgroundColor: '#82B1FF', textAlign: 'center' }} size="large">{totalSales}</Avatar>
//   </Col>
//   <Col xs={24} sm={2}>
//       Withdrawn
//     <br/>
//     <Avatar style={{ backgroundColor: '#82B1FF' }} size="large">{totalTransfer}</Avatar>
//   </Col>
//   <Col xs={24} sm={2}>
//       Wallet
//     <br/>
//     <Avatar style={{ backgroundColor: '#82B1FF' }} size="large">{remaining}</Avatar>
//   </Col>
// </Row>
// </div>

export default TransferRequestList
