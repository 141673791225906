import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import _get from 'lodash/get'
import { Button, Space } from 'antd'
import { FileOutlined, LeftOutlined, RightOutlined, ScheduleOutlined, UserOutlined } from '@ant-design/icons'
import keydown, { Keys } from 'react-keydown'

const { left, right } = Keys
class PhotoTagNav extends Component {
  @keydown(left)
  goPrevious (event) {
    const {previousPhoto, onNavigate} = this.props
    const previousLink = this.link(previousPhoto)

    if (previousLink) onNavigate(previousLink)
  }

  @keydown(right)
  goNext (event) {
    const {nextPhoto, onNavigate} = this.props
    const nextLink = this.link(nextPhoto)

    if (nextLink) onNavigate(nextLink)
  }

  link = (photo) => {
    const id = _get(photo, '_id', '')
    if (!id) return ''
    return '/photos/' + id + '/edit'
  }
  render () {
    const {previousPhoto, nextPhoto, currentPhoto, onNavigate} = this.props
    const previousLink = this.link(previousPhoto)
    const nextLink = this.link(nextPhoto)
    const eventLink = '/events/' + _get(currentPhoto, 'event._id','') +'/photos?view=image'

    return <div>
      <Link to={eventLink}>
        <Button style={styles.button}>
          <ScheduleOutlined />
          {_get(currentPhoto, 'event.title', '')}
        </Button>
      </Link>
      <Button disabled style={styles.button}>
        <UserOutlined />
        {_get(currentPhoto, 'photographer.displayName', '')}
      </Button>
      <Button disabled style={styles.button}>
        <FileOutlined />
        {_get(currentPhoto, 'fileName', '')}
      </Button>
      <Space.Compact block style={styles.button}>
        <Button type="primary" disabled={!previousLink}
          onClick={() => onNavigate(previousLink)}>
          <LeftOutlined />
          <FormattedMessage id="app.previous" defaultMessage="Previous"/>
        </Button>
        <Button type="primary" disabled={!nextLink}
          onClick={() => onNavigate(nextLink)}>
          <FormattedMessage id="app.next" defaultMessage="Next"/>
          <RightOutlined />
        </Button>
      </Space.Compact>
    </div>
  }
}
const styles = {
  button: {
    margin: '10px'
  }
}
export default PhotoTagNav
