import React from 'react'
import { Row, Col } from 'antd'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../styles/dashboard.css'
import eventCountQuery from '~/graphql/queries/eventCount.gql'
import orderTotalAndAvgQuery from '~/graphql/queries/orderTotalAndAvg.gql'

const DashboardStats = ({ title, year, month, income, theme }) => {
  let startDate
  let endDate

  if (month) {
    startDate = moment().month(month).year(year).startOf('month')
    endDate = moment().month(month).year(year).endOf('month')
  } else {
    startDate = moment().year(year).startOf('year')
    endDate = moment().year(year).endOf('year')
    // startDate = `${year-1}-12-31T17:00:00.000Z`
    // endDate = `${year}-12-31T17:00:00.000Z`
  }

  // const lastYearStart = startDate

  const intl = useIntl()
  const { error: eventCountErr, loading: eventCountLoading, data: eventCountData } = useQuery(eventCountQuery, {
    variables: {
      startAndEnd: [
        { '_operators': { 'startDate': { 'gte': startDate } } },
        { '_operators': { 'startDate': { 'lte': endDate } } }
      ]
    }
  })
  const { error: eventCountPartnerErr, loading: eventCountPartnerLoading, data: eventCountPartnerData } = useQuery(eventCountQuery, {
    variables: {
      officialPartner: true,
      // rental: [{ "photoDownloadMode": "priced" }, { "photoDownloadMode": "restricted" }],
      rental: { 'ne': 'free' },
      startAndEnd: [
        { '_operators': { 'startDate': { 'gte': startDate } } },
        { '_operators': { 'startDate': { 'lte': endDate } } }
      ]
    }
  })
  const { error: eventCountNonPartnerErr, loading: eventCountNonPartnerLoading, data: eventCountNonPartnerData } = useQuery(eventCountQuery, {
    variables: {
      nonPartner: { 'ne': true },
      // rental: [{ "photoDownloadMode": "priced" }, { "photoDownloadMode": "restricted" }],
      rental: { 'ne': 'free' },
      startAndEnd: [
        { '_operators': { 'startDate': { 'gte': startDate } } },
        { '_operators': { 'startDate': { 'lte': endDate } } }
      ]
    }
  })
  const { error: orderTAAErr, loading: orderTAALoading, data: orderTAAData } = useQuery(orderTotalAndAvgQuery, {
    variables: {
      'startDate': startDate,
      'endDate': endDate
    }
  })
  const { error: lastYearOrderTAAErr, loading: lastYearOrderTAALoading, data: lastYearOrderTAAData } = useQuery(orderTotalAndAvgQuery, {
    variables: {
      'startDate': moment(startDate).year(year - 1),
      'endDate': moment(endDate).year(year - 1)
    }
  })

  const { orderTotalAndAvg } = orderTAAData || {}
  const { orderTotalAndAvg: lastYearOrderTotalAndAvg } = lastYearOrderTAAData || {}
  const { eventCount } = eventCountData || {}
  const { eventCount: eventCountPartner } = eventCountPartnerData || {}
  const { eventCount: eventCountNonPartner } = eventCountNonPartnerData || {}
  if (orderTAAErr || eventCountErr || eventCountPartnerErr || eventCountNonPartnerErr || lastYearOrderTAAErr) {
    return 'error'
  }
  if (orderTAALoading || eventCountLoading || eventCountPartnerLoading || eventCountNonPartnerLoading || lastYearOrderTAALoading) {
    return 'loading...'
  }

  return (
    <Col xs={24} style={{ marginBottom: '10px' }} >
      <div style={{ width: '100%', background: '#F3FBFF', fontWeight: 'bold', marginBottom: '10px', padding: '5px 5px' }}>
        <span>{title}</span>
      </div>
      <Row type="flex">
        <Col xs={8}>
          <div className="data-div" >
            <p>% Avg. Sold/Uploaded Photos</p>
            <p className={theme}>% N/A</p>
            <p className="sub-data">{intl.formatNumber(orderTotalAndAvg?.total)} (total sold)</p>
            <p className="sub-data">N/A (total uploaded)</p>
            <p className="sub-data"><FontAwesomeIcon icon={['fas', 'caret-up']} style={{ color: '#52c41b' }} /> % N/A (last year % Avg.)</p>
          </div>
        </Col>
        <Col xs={8}>
          <div className="data-div" >
            <p>% Paid Downloads</p>
            <p className={theme}>% N/A</p>
            <p className="sub-data">{intl.formatNumber(orderTotalAndAvg?.total)} (from N/A total downloads)</p>
          </div>
        </Col>
        <Col xs={8}>
          <div className="data-div" >
            <p>% T.R. Share</p>
            <p className={theme}>% {(((income - orderTotalAndAvg?.organizerShareTotal - orderTotalAndAvg?.photographerShareTotal - orderTotalAndAvg?.brokerShareTotal) / income) * 100).toFixed(2)}</p>
            <p className="sub-data">{intl.formatNumber(income - orderTotalAndAvg?.organizerShareTotal - orderTotalAndAvg?.photographerShareTotal - orderTotalAndAvg?.brokerShareTotal)} (from gross income)</p>
          </div>
        </Col>
      </Row>
      <Row type="flex" style={{ marginTop: '10px' }}>
        <Col xs={8}>
          <div className="data-div" >
            <p>Avg. Photo/Transaction</p>
            <p className={theme}>{intl.formatNumber(orderTotalAndAvg?.avg.toFixed(2))}</p>
            <p className="sub-data">{intl.formatNumber(orderTotalAndAvg?.count)} (total trans.)</p>
            <p className="sub-data">
              {orderTotalAndAvg?.avg > lastYearOrderTotalAndAvg?.avg ? <FontAwesomeIcon icon={['fas', 'caret-up']} style={{ color: '#52c41b' }} /> : <FontAwesomeIcon icon={['fas', 'caret-down']} style={{ color: 'red' }} />} {intl.formatNumber(lastYearOrderTotalAndAvg?.avg.toFixed(2))} (last year Avg.)
            </p>
          </div>
        </Col>
        <Col xs={8}>
          <div className="data-div" >
            <p>Partner Events</p>
            <p className={theme}>% {(eventCountPartner / eventCount * 100).toFixed(2)}</p>
            <p className="sub-data">{intl.formatNumber(eventCountPartner)} (partner events)</p>
            <p className="sub-data">{intl.formatNumber(eventCount)} (total events)</p>
          </div>
        </Col>
        <Col xs={8}>
          <div className="data-div" >
            <p>Non-Partner Events</p>
            <p className={theme}>% {(eventCountNonPartner / eventCount * 100).toFixed(2)}</p>
            <p className="sub-data">{intl.formatNumber(eventCountNonPartner)} (non-partner events)</p>
            <p className="sub-data">{intl.formatNumber(eventCount)} (total events)</p>
          </div>
        </Col>
      </Row>
    </Col>
  )
}

export default DashboardStats
