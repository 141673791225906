import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from '@apollo/client'
import { compose } from 'recompose'
import { Button, Form, InputNumber, Modal, Spin, Typography, message } from 'antd'
import purchaseEventCreditsMutation from '~/graphql/mutations/purchaseEventCredits.gql'
import eventQuery from '~/graphql/queries/event.gql'
import EventSelect from './EventSelect'
import { inject } from 'mobx-react'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
  },
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 10,
    },
  },
}

const EventCreditDetail = ({ eventId }) => {
  const intl = useIntl()
  const { error, loading, data } = useQuery(eventQuery, { variables: { _id: eventId }, fetchPolicy: 'network-only' })
  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ display: 'block' }} />
  }
  const total = data?.event?.creditBalance?.total ?? 0
  return <>{intl.formatNumber(total)}</>
}

const AddEventCredits = compose(inject('router'))(({ router }) => {
  const [form] = Form.useForm()
  const intl = useIntl()
  const { resetFields } = form
  const { location } = router
  const { e: eventId } = location.query
  const [loading, setLoading] = useState(false)
  const [purchaseEventCredits] = useMutation(purchaseEventCreditsMutation)
  const handleFormSubmit = async (values) => {
    setLoading(true)
    const { creditAmount } = values
    try {
      await purchaseEventCredits({
        variables: { creditAmount, method: 'free_event', params: {}, eventId },
        refetchQueries: [{ query: eventQuery, variables: { _id: eventId } }],
      })
      resetFields()
      Modal.success({ content: `Add credits successful` })
    } catch (err) {
      message.error(err.message)
    }
    setLoading(false)
  }
  const handleEventId = (v) => {
    location.query = {
      ...location.query,
      e: v,
    }
  }

  return (
    <>
      {eventId && (
        <div className='rounded-container' style={{ textAlign: 'center', padding: '1em 5em' }}>
          <Typography.Title level={3}>
            {intl.formatMessage({ id: 'app.totalCredits', defaultMessage: 'Total Credits' })}
          </Typography.Title>
          <Typography.Text strong style={{ fontSize: '3em' }}>
            <EventCreditDetail eventId={eventId} />
          </Typography.Text>
        </div>
      )}
      {loading ? (
        <Spin style={{ display: 'block' }} />
      ) : (
        <div id='add-credits' className='rounded-container' style={{ padding: '2em 4em 0em 4em' }}>
          <Form form={form} onFinish={handleFormSubmit} style={{ width: 500 }}>
            <Form.Item
              {...formItemLayout}
              name={['eventId']}
              label={intl.formatMessage({ id: 'app.event', defaultMessage: 'Event' })}
              initialValue={eventId}
              rules={[{ required: true }]}
            >
              <EventSelect onChange={handleEventId} variables={{ creditTargets: 'event' }} style={{ width: 260 }} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name={['creditAmount']}
              label={intl.formatMessage({ id: 'app.selectCreditAmount', defaultMessage: 'Select Credit Amount' })}
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type='primary' htmlType='submit'>
                {intl.formatMessage({ id: 'app.submit', defaultMessage: 'Submit' })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  )
})

export default AddEventCredits
