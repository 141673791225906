import { observable, action } from 'mobx'
import { Cookies } from 'react-cookie'

const cookie = new Cookies()

export class I18NStore {
  @observable locale = cookie.get('locale') || window.location.origin.includes('thai') ? 'th' : 'en'
  @action
  setLocale (locale) {
    this.locale = locale
    cookie.set('locale', locale, { path: '/' })
  }
}
// const I18NStore = new I18NStore()

// export default I18NStore
