import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Button, Popover, Table } from 'antd'
import photoPackagesQuery from '~/graphql/queries/photoPackages.gql'
import config from '../config'

const Packages = () => {
  const { loading, error, data } = useQuery(photoPackagesQuery, {
    fetchPolicy: 'network-only'
  })
  if (error) {
    return `${error}`
  }
  const photoPackages = data?.photoPackages ?? []
  return (<>
    <div className="filterTools" style={{ textAlign: 'right', padding: '6px' }}>
      <Button type="primary"><Link to="/packages/create">Create</Link></Button>
    </div>
    <Table dataSource={photoPackages} loading={loading} >
      <Table.Column
        title="name"
        key="name"
        dataIndex={['name']}
        render={(name, { _id, description }) =>
          <Popover content={description} ><Link to={`/packages/${_id}/edit`}>{name}</Link></Popover>
        }
      />
      <Table.Column title="type" key="packageType" dataIndex={['packageType']} />
      <Table.Column title="items" key="minItemCount" dataIndex={['minItemCount']} />
      <Table.Column title={<FormattedMessage id="app.size" defaultMessage="Size" />} key="photoSize" dataIndex={['photoSize']} />
      <Table.Column title="price/discount" key="discount" render={(p => {
        if (p.packageType === 'discount_fixed') {
          return `-${p.amount} ${config.currency}`
        }
        if (p.packageType === 'discount_percentage') {
          return `-${p.amount}%`
        }
        if (p.packageType === 'price_fixed') {
          return `${p.amount} ${config.currency}`
        }
      })} />
      <Table.Column title="events" key="eventCount" dataIndex={['eventCount']} />
    </Table>
  </>)
}

export default Packages
