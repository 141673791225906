import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { inject, observer }  from 'mobx-react'

@inject('user')
@observer
class PrivateRoute extends Component {
  render() {
    const { component: Component, user, ...rest } = this.props
    const authenticated = user.initUser() && user._id
    return (
      <Route {...rest} render={props => 
        authenticated
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login', state: { from: props.location }}} />
        }
      />
    )
  }
}

export default PrivateRoute
