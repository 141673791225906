import React from 'react'
import { message } from 'antd'
import CouponTypeForm from './CouponTypeForm'
import { inject } from 'mobx-react'
import { useMutation } from '@apollo/client'
import createCouponTypeMutation from '~/graphql/mutations/createCouponType.gql'
import couponCodeTypesPaginationQuery from '../graphql/queries/couponCodeTypesPagination.gql'

const CouponTypeCreate = ({ router }) => {
  const [createCouponType] = useMutation(createCouponTypeMutation, {
    refetchQueries: [{ query: couponCodeTypesPaginationQuery }],
  })

  const handleSubmit = async (values) => {
    const { data } = await createCouponType({
      variables: values,
    })
    message.info(`Coupon code type "${data?.createCouponType?.record?.name}" created`, 10)
    router.push('/coupon-types')
  }

  return <CouponTypeForm onSubmit={handleSubmit} />
}

export default inject('router')(CouponTypeCreate)
