import React, { useState, useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { FormattedMessage, injectIntl } from 'react-intl'
import ReactMarkdown from 'react-markdown'
import compose from 'recompose/compose'
import openingCampaignsQuery from '~/graphql/queries/openingCampaigns.gql'
import registerCampaignMutation from '~/graphql/mutations/registerCampaign.gql'
import { Modal } from 'antd'
import { inject } from 'mobx-react'

const CampaignModal = compose(
  injectIntl,
  inject('user')
)(({ intl, user, campaign }) => {
  if (!user._id || user.role !== 'photographer') {
    return null
  }
  const [open, setOpen] = useState(true)
  const [registerCampaign] = useMutation(registerCampaignMutation)
  const register = useCallback(async (agree) => {
    await registerCampaign({ variables: { campaignId: campaign._id, agree } })
    setOpen(false)
    Modal.info({
      centered: true,
      keyboard: false,
      content: agree ? campaign.agreeMessage : campaign.disagreeMessage,
      // onOk: () => setOpen(false),
      // okText: intl.formatMessage({ id: 'app.backToHome', defaultMessage: 'Back to Home' })
      okText: intl.formatMessage({ id: 'app.action.close', defaultMessage: 'Close' })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return <Modal
    key={campaign._id}
    centered
    open={open}
    keyboard={false}
    closable={false}
    maskClosable={false}
    title={campaign.title}
    className="campaign markdown"
    onOk={() => register(true)}
    okText={<FormattedMessage id="app.campaignConsentAgree" defaultMessage="Agree" />}
    okButtonProps={{ type: 'primary' }}
    onCancel={() => register(false)}
    cancelText={<FormattedMessage id="app.campaignConsentDisagree" defaultMessage="Disagree" />}
    cancelButtonProps={{ type: 'primary', danger: true }}
  >
    <ReactMarkdown>{campaign.content}</ReactMarkdown>
  </Modal>
})

const CampaignConsent = () => {
  const { loading, error, data } = useQuery(openingCampaignsQuery)

  if (loading) {
    return null
  }
  if (error) {
    console.error(error)
    return null
  }
  const { openingCampaigns = [] } = data
  const modalCampaigns = openingCampaigns.filter(campaign => campaign.currentUserAgrees === null)
  return <React.Fragment>{modalCampaigns.map(campaign =>
    <CampaignModal key={campaign._id} campaign={campaign} />)
  }</React.Fragment>
}

export default CampaignConsent
