import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import Login from './Login'
import Register from './Register'
import ForgotPassword from './ForgotPassword'
import PasswordReset from './PasswordReset'
import App from './App'

import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache, from, defaultDataIdFromObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Router } from 'react-router-mobx'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import PrivateRoute from '~/components/PrivateRoute'
import I18nProvider from '~/providers/I18nProvider'

import { StoresProvider, routerStore, userStore, i18nStore } from './contexts'
import moment from 'moment-timezone'
import './App.css'
import config from './config'
moment.tz('Asia/Bangkok')

const stores = {
  i18n: i18nStore,
  user: userStore,
  router: routerStore
}
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URI
})
// const uploadLink = createUploadLink({ uri: process.env.REACT_APP_API_URI })
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const errorLink = onError(({ networkError = {}, graphQLErrors }) => {
  // graphQLErrors.forEach(error => message.error(error.message))
  if (networkError.statusCode === 401) {
    userStore.logout()
    routerStore.push('/login')
  }
})

const headerLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-RunX-Platform': config.platform
      // 'X-RunX-Platform':  window.location.origin.includes('harimau') ? 'malay' : 'thai'
      // authorization: token ? `Bearer ${token}` : "",
    }
  }
});

// console.log(config.platform);

const client = new ApolloClient({
  link: from([authLink, errorLink, headerLink, httpLink]),
  cache: new InMemoryCache({
    dataIdFromObject: object => {
      if (['PhotoSizes', 'EventPhotoSizes'].includes(object.__typename)) {
        return null
      }
      if (['Photo', 'FacePhoto'].includes(object.__typename)) {
        return object._id
      }
      return defaultDataIdFromObject(object)
    }
  })
})

userStore.onLogout = () => {
}

ReactDOM.render(
  <ApolloProvider client={client} >
    <Helmet>
      <meta name="description" content={config.title} />
    </Helmet>
    <StoresProvider>
      <Provider {...stores}>
        <I18nProvider>
          <React.Fragment>
            {/* <Modal open={true} footer={null} destroyOnClose={false}>
            ขณะนี้กำลังดำเนินการปรับปรุงระบบชั่วคราว ขออภัยในความไม่สะดวก
          </Modal> */}
            <Router component={BrowserRouter} routerStore={routerStore}>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route path="/passwordreset" component={PasswordReset} />
                <PrivateRoute path="/" component={App} />
              </Switch>
            </Router>
          </React.Fragment>
        </I18nProvider>
      </Provider>
    </StoresProvider>
  </ApolloProvider>,
  document.getElementById('root')
)
