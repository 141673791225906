import React, { useState } from 'react'
import { Button, Modal, message, Tag, Popover } from 'antd'
import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import updateAdminUserMutation from '~/graphql/mutations/updateAdminUser.gql'
import adminUserListQuery from '~/graphql/queries/adminUserList.gql'
import { FormattedMessage } from 'react-intl'

const AdminuserApproveButton = ({ adminuser }) => {
  const [verifiedAdminUser] = useMutation(updateAdminUserMutation)
  const [open, setOpen] = useState(false)
  const verifie = async (verified) => {
    try {
      await verifiedAdminUser({
        variables: {
          _id: adminuser._id,
          record: {
            verified,
          },
        },
        refetchQueries: [{ query: adminUserListQuery }],
      })
      message.success('Approval success', 4)
    } catch (err) {
      message.error(err)
    } finally {
      setOpen(false)
    }
  }
  const handleClick = () => setOpen(true)

  return <>
    {typeof adminuser.verified !== 'boolean'
      ? <Button onClick={handleClick}><CloseOutlined />|<CheckOutlined /></Button>
      : (adminuser.verified
        ? <Tag color="green"><CheckCircleOutlined /> <FormattedMessage id="app.approved" defaultMessage="Approved" /></Tag>
        : <Popover content={adminuser.verifieMessage}><Tag color="red"><CloseCircleOutlined /> <FormattedMessage id="app.rejected" defaultMessage="Rejected" /></Tag></Popover>
      )
    }
    <Modal
      open={open}
      closable
      // onCancel={(e) => console.log(e, e.button, e.target)}
      onCancel={() => setOpen(false)}
      maskClosable
      footer={
        <>
          <Button icon={<CloseOutlined />} type="primary" onClick={() => verifie(false)} danger><FormattedMessage id="app.reject" defaultMessage="Reject" /></Button>
          <Button icon={<CheckOutlined />} type="primary" onClick={() => verifie(true)}><FormattedMessage id="app.approve" defaultMessage="Approve" /></Button>
        </>
      }
    >
      <React.Fragment>
        <div><FormattedMessage id="app.username" defaultMessage="User Name" /> {adminuser.username}</div>
        <div><FormattedMessage id="app.citizenID" defaultMessage="Citizen ID" /> {adminuser.profile.nationalId}</div>
        <div><FormattedMessage id="app.username" defaultMessage="First Name" /> {adminuser.profile.firstName}</div>
        <div><FormattedMessage id="app.lastName" defaultMessage="Last Name" /> {adminuser.profile.lastName}</div>
        <div><FormattedMessage id="app.displayName" defaultMessage="Display Name" />: {adminuser.displayName}</div>
        <div><FormattedMessage id="app.email" defaultMessage="Email" />: {adminuser.profile.email}</div>
        <div><FormattedMessage id="app.phone" defaultMessage="Phone" />: {adminuser.profile.phone}</div><hr/>
        <div><FormattedMessage id="app.bank" defaultMessage="Bank" />: {adminuser.bankAccount.brand}</div>
        <div><FormattedMessage id="app.accountNumber" defaultMessage="Account Number" />: {adminuser.bankAccount.number}</div>
        {adminuser.bankAccount.passbookImageUrl && <a href={adminuser.bankAccount.passbookImageUrl} target="_blank" rel="noopener noreferrer">{adminuser.bankAccount.passbookImageUrl}</a>}
        {adminuser.bankAccount.passbookImageUrl ? <div>
          <iframe width="100%" height="400px" title="Passbook Image" src={adminuser.bankAccount.passbookImageUrl} />
        </div> : 'No Document'}
      </React.Fragment>
    </Modal>
  </>
}

export default AdminuserApproveButton
