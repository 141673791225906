import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { inject } from 'mobx-react'
import { useQuery } from '@apollo/client'
import { compose } from 'recompose'
import { Button, Col, Input, Row, Spin, Table } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import eventItemsQuery from '~/graphql/queries/eventItems.gql'

const EventCreditStats = compose(inject('router'))(({ router }) => {
  const { location } = router
  const { p: page, s: search } = location.query
  const { error, loading, data } = useQuery(eventItemsQuery, {
    variables: {
      creditTargets: 'event',
      titleSearch: search,
      page: page && parseInt(page, 10),
    },
    fetchPolicy: 'network-only',
  })

  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }

  const { currentPage: current, perPage: pageSize, itemCount: total } = data?.eventItems?.pageInfo ?? {}
  const handleSearch = (v) => {
    const { p, ...query } = location.query
    location.query = {
      ...query,
      s: v,
    }
  }
  const handlePagination = (v) => {
    location.query = {
      ...location.query,
      p: v,
    }
  }

  return (
    <div style={{ textAlign: 'right', padding: '6px' }}>
      <Row gutter={[6, 12]} justify={'end'}>
        <Col xs={24} sm={{ span: 8 }}>
          <Input.Search allowClear enterButton defaultValue={search} onSearch={handleSearch} />
        </Col>
      </Row>
      <Row gutter={[6, 12]}>
        <Col span={24}>
          <Table
            rowKey='_id'
            dataSource={data?.eventItems?.items ?? []}
            loading={loading}
            scroll={{ x: true }}
            pagination={{ current, pageSize, total, onChange: handlePagination, showQuickJumper: true }}
          >
            <Table.Column title={<FormattedMessage id='app.eventTitle' defaultMessage='Event Title' />} dataIndex={['title']} />
            <Table.Column title={<FormattedMessage id='app.credits' defaultMessage='Credits' />} dataIndex={['creditBalance', 'credit']} />
            <Table.Column title={<FormattedMessage id='app.debits' defaultMessage='Dedits' />} dataIndex={['creditBalance', 'debit']} />
            <Table.Column title={<FormattedMessage id='app.totalCredits' defaultMessage='Total Credits' />} dataIndex={['creditBalance', 'total']} />
            <Table.Column
              title={<FormattedMessage id='app.addEventCredits' defaultMessage='Add Event Credits' />}
              dataIndex={['_id']}
              render={(_id) => (
                <Link to={`/credits/add?e=${_id}`}>
                  <Button>
                    <PlusCircleOutlined />
                  </Button>
                </Link>
              )}
            />
          </Table>
        </Col>
      </Row>
    </div>
  )
})

export default EventCreditStats
