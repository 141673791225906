import React from 'react'
import { Button, Form, Input, Select, message, InputNumber } from 'antd'
import partnersQuery from '~/graphql/queries/organizers.gql'
import addOrganizerMutation from '~/graphql/mutations/addOrganizer.gql'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
// import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import supportedBanks from '~/data/supportedBanks.json'
import CloudImageUploader from '~/components/CloudImageUploader'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 10,
    },
  },
}

const Partner = ({ history }) => {
  const [form] = Form.useForm()
  const { getFieldValue, resetFields } = form
  const [addOrganizer] = useMutation(addOrganizerMutation, {
    refetchQueries: [
      {
        query: partnersQuery,
      },
    ],
  })
  const { Option } = Select
  const intl = useIntl()
  const handleSubmit = async (values) => {
    const {
      address,
      companyName,
      formalName,
      identityDocumentUrl,
      name,
      nationalId,
      organizerShare,
      organizerShareUnit,
      phone,
      photographerShare,
      bankAccount: { brand, name: accountName, number, passbookImageUrl },
    } = values
    try {
      await addOrganizer({
        variables: {
          record: {
            address,
            companyName,
            formalName,
            identityDocumentUrl,
            name,
            nationalId,
            organizerShare,
            organizerShareUnit,
            phone,
            photographerShare,
            bankAccount: {
              brand,
              name: accountName,
              number,
              passbookImageUrl,
            },
          },
        },
      })
      message.info(`Partner "${name}" created`, 5)
      resetFields()
      history.push('/partners')
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <div style={{ padding: '10px' }}>
      <div
        style={{
          width: '1000px',
          margin: 'auto',
          marginTop: '2em',
          border: '1px solid #707070',
          borderBottom: 'none',
          textAlign: 'center',
          padding: '10px',
        }}
      >
        {intl.formatMessage({ id: 'app.addPartner', defaultMessage: 'Add Partner' })}
      </div>
      <Form
        form={form}
        onFinish={handleSubmit}
        colon={false}
        style={{ width: '1000px', margin: 'auto', paddingTop: '20px', border: '1px solid #707070' }}
      >
        <Form.Item {...formItemLayout} name={['name']} label={intl.formatMessage({ id: 'app.partnerName', defaultMessage: 'Partner Name' })}>
          <Input />
        </Form.Item>
        <Form.Item {...formItemLayout} name={['formalName']} label={intl.formatMessage({ id: 'app.formalName', defaultMessage: 'Formal Name' })}>
          <Input />
        </Form.Item>
        <Form.Item {...formItemLayout} name={['companyName']} label={intl.formatMessage({ id: 'app.companyName', defaultMessage: 'Company Name' })}>
          <Input />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['nationalId']}
          label={intl.formatMessage({ id: 'app.citizen/tax', defaultMessage: 'Citizen ID/Tax ID' })}
        >
          <Input />
        </Form.Item>
        <Form.Item {...formItemLayout} name={['address']} label={intl.formatMessage({ id: 'app.partnerAddress', defaultMessage: 'Address' })}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item {...formItemLayout} name={['phone']} label={intl.formatMessage({ id: 'app.phone', defaultMessage: 'Phone' })}>
          <Input />
        </Form.Item>
        <div>
          <Form.Item
            {...formItemLayout}
            name={['bankAccount', 'brand']}
            label={intl.formatMessage({ id: 'app.selectBank', defaultMessage: 'Select Bank' })}
            initialValue={'none'}
            rules={[
              {
                required: getFieldValue('link_bank_account'),
                message: intl.formatMessage({ id: 'app.pleaseSelectBank', defaultMessage: 'Please Select Bank!' }),
              },
            ]}
          >
            <Select>
              <Option key={'none'} value='none'>
                {intl.formatMessage({ id: 'app.selectBank', defaultMessage: 'Select Bank' })}
              </Option>
              {supportedBanks.map((bankId) => (
                <Option key={bankId} value={bankId}>
                  {intl.formatMessage({ id: `app.banks.${bankId}` })}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['bankAccount', 'number']}
            label={intl.formatMessage({ id: 'app.accountNumber', defaultMessage: 'Account Number' })}
            initialValue={''}
            rules={[
              {
                required: getFieldValue('link_bank_account'),
                len: 10,
                message: intl.formatMessage({ id: 'app.pleaseInputYourAccountNumber', defaultMessage: 'Please input your account number!' }),
              },
            ]}
          >
            <Input placeholder={intl.formatMessage({ id: 'app.accountNumber', defaultMessage: 'Account Number' })} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['bankAccount', 'name']}
            label={intl.formatMessage({ id: 'app.accountName', defaultMessage: 'Account Name' })}
            initialValue={''}
            rules={[
              {
                required: getFieldValue('link_bank_account'),
                message: intl.formatMessage({ id: 'app.pleaseInputYourAccountName', defaultMessage: 'Please input your account name!' }),
              },
            ]}
          >
            <Input placeholder={intl.formatMessage({ id: 'app.accountName', defaultMessage: 'Account Name' })} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['bankAccount', 'passbookImageUrl']}
            label={intl.formatMessage({ id: 'app.passbookImageUrl', defaultMessage: 'Bank Account Book' })}
            // rules={[{ ...(!isAdmin && { required: true }), message: intl.formatMessage({ id: 'app.passbookRequired', defaultMessage: 'Please upload document' }) }]}
          >
            <CloudImageUploader provider='gs' path='passbooks' />
          </Form.Item>
        </div>
        <Form.Item
          {...formItemLayout}
          name={['identityDocumentUrl']}
          label={intl.formatMessage({ id: 'app.identityDocument', defaultMessage: 'Identity Document' })}
          // rules={[{ ...(!isAdmin && { required: true }), message: intl.formatMessage({ id: 'app.passbookRequired', defaultMessage: 'Please upload document' }) }]}
        >
          <CloudImageUploader provider='gs' path='ids' />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'app.partnerShare', defaultMessage: 'Partner Share' })}
          style={{ marginBottom: 0 }}
        >
          <Form.Item name={['organizerShare']} initialValue={4.29} style={{ display: 'inline-block', width: '40%' }}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name={['organizerShareUnit']} initialValue={'percentage'} style={{ display: 'inline-block', width: '30%', padding: '0px 10px' }}>
            <Select>
              <Option value='fixed'>{intl.formatMessage({ id: 'app.baht', defaultMessage: 'Baht' })}</Option>
              <Option value='percentage'>%</Option>
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item {...formItemLayout} label={intl.formatMessage({ id: 'app.photographerShare', defaultMessage: 'Photographer Share' })}>
          <Form.Item name={['photographerShare']} initialValue={57.14} style={{ display: 'inline-block', width: '40%' }}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item style={{ display: 'inline-block', width: '30%', padding: '0px 10px' }}>
            <span>%</span>
          </Form.Item>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type='primary' htmlType='submit' style={{ marginRight: '10px' }}>
            {intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' })}
          </Button>
          <Link to='/partners'>
            <Button type='default'>{intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancel' })}</Button>
          </Link>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Partner
