import React, { Component } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { graphql } from '@apollo/client/react/hoc'
import { Table, Input, Button, Pagination } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'

// import { inject, observer } from 'mobx-react'
import _get from 'lodash/get'
// import _pickBy from 'lodash/pickBy'
// import gql from 'graphql-tag'

// import EventItem from './EventItem'
import eventsOrdersQuery from '~/graphql/queries/eventsOrders.gql'
import config from '../config'
// import ConnectedLocaleProvider from '../providers/I18nProvider';
@inject('router')
@graphql(eventsOrdersQuery, {
  options: ({ router }) => {
    const { p, s } = router.location.query
    return { variables: {
      search: s,
      page: parseInt(p, 10) || 1
    }}
  }
})
@observer
class EventsOrders extends Component {
  state = {
    tableLoading: false,
    dropdownOpen: false,
    searchText: '',
    page: 1
  }
  // componentWillMount() {
  //   console.log()
  // }
  componentWillReceiveProps (nextProps) {
    const loading = _get(nextProps, 'data.loading', false)
    if (!loading) {
      this.switchTableLoading(false)
    }
  }
  switchTableLoading = (bool, cb) => {
    this.setState({ tableLoading: bool }, cb)
  }
  onSearch = () => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      s: this.state.searchText
    }
  }
  onPaginationChange = (page) => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      p: page
    }
    this.switchTableLoading(true, window.scroll(0, 0))
  }

  handleChange = e => {
    this.setState({
      searchText: e.target.value
    })
  }
  render () {
    // console.log({ props: this.props })
    // if (this.props.data.loading) return <div>Loading ...</div>
    const eventsOrders = _get(this.props.data, 'eventsOrders.items', [])
    const pageInfo = _get(this.props.data, 'eventsOrders.pageInfo', {})

    const columns = [{
      title: <FormattedMessage id="app.event" defaultMessage="Event"/>,
      dataIndex: ['eventId'],
      key: 'event',
      filterDropdown: (
        <div style={{
          padding: '8px',
          borderRadius: '6px',
          background: '#fff',
          boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
        }}>
          <Input
            ref={ele => this.searchInput = ele}
            placeholder="Search Event"
            value={this.state.searchText}
            onChange={this.handleChange}
            onPressEnter={this.onSearch}
            style={{
              width: '130px',
              marginRight: '8px'
            }}
          />
          <Button type="primary" onClick={this.onSearch} >Search</Button>
        </div>
      ),
      filterIcon: <SearchOutlined />,
      filterDropdownOpen: this.state.dropdownOpen,
      onFilterDropdownOpenChange: open => {
        this.setState({
          dropdownOpen: open
        }, () => this.searchInput && this.searchInput.focus())
      }
    }, {
      title: <FormattedMessage id="app.totalPhotos" defaultMessage="Total Photos"/>,
      // key: 'photos',
      dataIndex: ['totalCount']
      // render: (text, record) => getTotalPhotos({ eventId: record.eventId })
    }, {
      title: <FormattedMessage id="app.photoSold" defaultMessage="Photo Sold"/>,
      dataIndex: ['count'],
      key: 'count'
    }, {
      title: <FormattedMessage id="app.salePercentage" defaultMessage="Sale Percentage"/>,
      key: 'percentage',
      render: (text, record) => (`${(record.count / record.totalCount * 100).toFixed(4)}%`)
    }, {
      title: <FormattedMessage id="app.amountSold" defaultMessage="Amount Sold"/>,
      dataIndex: ['amount'],
      key: 'amount',
      render: amount => <FormattedNumber value={amount} style="currency" currency={config.currency} currencySign="standard" /> // eslint-disable-line react/style-prop-object
    }]
    return <div>
      <Table
        loading={this.state.tableLoading}
        columns={columns}
        dataSource={eventsOrders}
        rowKey={record => `${record.eventId}`}
        pagination={{
          position: 'none',
          pageSize: 30
        }} />
      {pageInfo && pageInfo.pageCount > 1
        ? <div style={{ textAlign: 'right', margin: '20px'}}>
          <Pagination
            onChange={this.onPaginationChange}
            defaultCurrent={pageInfo.currentPage}
            defaultPageSize={pageInfo.perPage}
            total={pageInfo.itemCount} />
        </div> : null
      }
    </div>
    // console.log(events)
    // return <div>evntsorders</div>
  }
}

export default EventsOrders
