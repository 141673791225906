import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Spin } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'
import * as XLSX from 'xlsx'
import couponCodesQuery from '../graphql/queries/couponCodes.gql'

const CouponExport = ({ couponCodeTypeId, couponTypeName }) => {
  const [couponExport, { error, loading, data }] = useLazyQuery(couponCodesQuery, { fetchPolicy: 'network-only' })
  useEffect(() => {
    if (data?.couponCodes) {
      const exportData = data?.couponCodes?.map((item) => {
        return {
          Code: item.code,
          'Max use per coupon': item.copies,
          'Max use per user (email)': item.copiesPerUser,
          'Order Coupons': item.orderCount,
        }
      })
      exportToCSV([exportData], `${couponTypeName}_${moment().format('DD_MM_YYYY')}.xlsx`, [couponTypeName])
    }
  }, [data?.couponCodes]) // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin />
  }

  const handleExport = () => {
    couponExport({ variables: { couponCodeTypeId, limit: 100000 } })
  }
  const exportToCSV = (apiData, fileName = 'unknown.xlsx', SheetNames) => {
    const ws = apiData.map((item) => XLSX.utils.json_to_sheet(item))
    const Sheets = {}
    SheetNames.forEach((item, index) => (Sheets[item] = ws[index]))
    const wb = { Sheets, SheetNames }
    XLSX.writeFile(wb, fileName)
  }

  const exportData = data?.couponCodes?.map((item) => {
    return {
      Code: item.code,
      'Max use per coupon': item.copies,
      'Max use per user (email)': item.copiesPerUser,
      'Order Coupons': item.orderCount,
    }
  })

  return data?.couponCodes ? (
    <Button
      icon={<FileExcelOutlined />}
      type='primary'
      size='small'
      onClick={() => exportToCSV([exportData], `${couponTypeName}_${moment().format('DD_MM_YYYY')}.xlsx`, [couponTypeName])}
      style={{ margin: '0.3em 1em 1em 0' }}
    >
      <FormattedMessage id='app.export' defaultMessage='Export' />
    </Button>
  ) : (
    <Button icon={<FileExcelOutlined />} type='primary' size='small' onClick={handleExport} style={{ margin: '0.3em 1em 1em 0' }}>
      <FormattedMessage id='app.export' defaultMessage='Export' />
    </Button>
  )
}

export default CouponExport
