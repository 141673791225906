import React from 'react'
import { Affix } from 'antd'

const Toolbar = ({ left, right, children }) => <Affix style={{}}>
  <div style={{
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px',
    marginBottom: '12px',
    borderBottom: '1px solid #e8e8e8'
  }}>
    <div>{left}</div>
    <div>{right || children}</div>
  </div>
</Affix>

export default Toolbar
