import React, { useState } from 'react'
import FaceUpload from '~/components/FaceUpload'
import { CloseOutlined } from '@ant-design/icons'


const Face = ({ imageId, awsAccount, onInput, onClear }) => {
  // const { modalOpen, setModelOpen } = useState(false)
  const { fileName } = useState('')
  const active = !!imageId

  const handleSearch = (imageId) => {
    // console.log('handleSearch', { imageId })
    onInput(imageId)
  }

  const handleClear = (e) => {
    onClear()
    e.stopPropagation()
  }

  return (
    <div>
      {active ? (
        <div
        style={{
          display: "flex",
  
          alignItems: "center",
       
          boxSizing: "border-box",
          boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          width: "300px",
        }}
        >
          <div>
            {/* <Avatar icon={<PictureOutlined />} /> */}
            <span style={{ marginLeft: "10px" }}>{fileName || imageId}</span>
          </div>
          <CloseOutlined
            onClick={handleClear}
            style={{ cursor: "pointer" }}
            transform="down-2"
          />
          {/* <FontAwesomeIcon icon={['fas', 'times']} onClick={this.handleClear} /> */}
        </div>
      ) : (
        <FaceUpload
          imageId={imageId}
          onCompleted={handleSearch}
          awsAccount={awsAccount}
          setFileName={(name) => name}
          setImgUrl={(url) => url}
        />
      )}
    </div>
  )
}

export default Face
