import React from 'react'
import { useIntl } from 'react-intl'
// import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
// import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
// import { useMutation, useQuery } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { compose } from 'recompose'
import { Table, Button, Input, Row, Col } from 'antd'
// import moment from 'moment'
import salespersonListQuery from '~/graphql/queries/adminUserList.gql'
import config from '../config'

const Salespersons = compose(inject('router'))(({ router }) => {
  const { location } = router
  const { p: page, s: search } = location.query
  const intl = useIntl()
  const { error, loading, data } = useQuery(salespersonListQuery, {
    variables: {
      search: search || '',
      page: parseInt(page, 10),
      role: 'salesperson'
    }
  })
  const { currentPage, itemCount, perPage } = data?.adminUserList?.pageInfo ?? {}
  const handleSearch = (value) => {
    const { p, ...query } = location.query
    location.query = {
      ...query,
      s: value
    }
  }
  const handlePagination = (page) => {
    location.query = {
      ...location.query,
      p: `${page}`
    }
  }
  if (error) {
    return 'Error'
  }
  return (
    <>
      <Row gutter={[6, 12]} type='flex' justify='space-between'>
        <Col xs={24} sm={{ span: 16 }} lg={{ span: 8 }}>
          <Input.Search enterButton allowClear onSearch={handleSearch} />
        </Col>
        {/* <Col xs={24} sm=a{{ span: 8 }} lg={{ span: 4 }}>
        <Link to="/salespersons/add">
          <span><Button block icon={<PlusOutlined />} type="primary" > {intl.formatMessage({ id: 'app.addSalesperson', defaultMessage: 'Add Salesperson' })}</Button></span>
        </Link>
      </Col> */}
      </Row>
      <Row gutter={[6, 12]}>
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={data?.adminUserList?.items}
            rowKey={(record) => record._id}
            pagination={{
              pageSize: perPage,
              current: currentPage,
              total: itemCount,
              onChange: handlePagination
            }}
          >
            {/* <Table.Column
            title='No.'
            dataIndex={['number']}
            key='number'
            render={(text, record, index) => index + 1}
          /> */}
            <Table.Column
              title={intl.formatMessage({ id: 'app.salesperson', defaultMessage: 'Salesperson' })}
              dataIndex={['displayName']}
              key='displayName'
              render={(name, { _id }) => <Link to={`/events:balance:salesperson/${_id}`}>{name}</Link>}
            />
            <Table.Column
              title={`${intl.formatMessage({ id: 'app.salespersonPayable', defaultMessage: 'salespersonPayable'})} (${config.currency})`}
              dataIndex={['salespersonData', 'financial', 'balance']}
              key='salespersonData.financial.balance'
              render={(balance) => balance?.toFixed(2) || 0}
            />
            <Table.Column
              title={intl.formatMessage({ id: 'app.salespersonShare', defaultMessage: 'Sales Thai.run Share' })}
              dataIndex={['salespersonData', 'salespersonShare']}
              key='salespersonData.salespersonShare'
              render={(text, { salespersonData }) =>
                `${text || 0} ${
                  salespersonData?.salespersonShareUnit === 'fixed' ? intl.formatMessage({ id: 'app.baht', defaultMessage: 'Baht' }) : '%'
                }`
              }
            />
            <Table.Column
              title={intl.formatMessage({ id: 'app.action', defaultMessage: 'Action' })}
              dataIndex={['_id']}
              key='action'
              render={(_id) => (
                <div>
                  <Link to={`/users/${_id}/edit`}>
                    <Button type='link'>{intl.formatMessage({ id: 'app.edit', defaultMessage: 'Edit' })}</Button>
                  </Link>
                </div>
              )}
            />
          </Table>
        </Col>
      </Row>
    </>
  )
})

export default Salespersons
