import React from 'react'
import { Form, Modal, Input, Button, Row, Col, Spin } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import leftPad from 'left-pad'
import moment from 'moment-timezone'

import transferRequestQuery from '~/graphql/queries/transferRequest.gql'
import approveTransferRequestMutation from '~/graphql/mutations/approveTransferRequest.gql'
import rejectTransferRequestMutation from '~/graphql/mutations/rejectTransferRequest.gql'
import { useMutation, useQuery } from '@apollo/client'

const labelLayout = {
  xs: { span: 24 },
  sm: { span: 8 },
  style: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
}
const valueLayout = {
  xs: { span: 24 },
  sm: { span: 16 },
}
// const tagColors = {
//   pending: 'gold',
//   approved: 'blue',
//   cancelled: 'volcano',
//   rejected: 'red',
//   completed: 'green'
// }

const TransferRequestReviewModal = ({ id, onClose }) => {
  const [form] = Form.useForm()
  const { getFieldsValue } = form
  const { error, loading, data } = useQuery(transferRequestQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  })
  const [approveTransferRequest] = useMutation(approveTransferRequestMutation)
  const [rejectTransferRequest] = useMutation(rejectTransferRequestMutation)
  const { transferRequest } = data ?? {}
  const user = transferRequest?.user ?? {}
  const recipient = user?.recipient
  const handleApprove = async () => {
    const values = getFieldsValue(true)
    const { remark } = values
    try {
      await approveTransferRequest({
        variables: {
          id,
          remark,
        },
      })
      onClose()
    } catch (err) {
      console.error(err)
    }
  }
  const handleReject = async () => {
    const values = getFieldsValue(true)
    const { remark } = values
    try {
      await rejectTransferRequest({
        variables: {
          id,
          remark,
        },
      })
      onClose()
    } catch (err) {
      console.error(err)
    }
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  if (error) {
    return 'Error'
  }
  return (
    <Modal
      title='Review'
      open={!!id}
      maskClosable
      onCancel={onClose}
      footer={
        <React.Fragment>
          <Button icon={<CheckCircleOutlined />} type='primary' onClick={handleApprove}>
            <FormattedMessage id='app.approved' defaultMessage='Approved' />
          </Button>
          <Button icon={<CloseCircleOutlined />} type='primary' onClick={handleReject} danger>
            <FormattedMessage id='app.rejected' defaultMessage='Rejected' />
          </Button>
          <Button icon={<CloseOutlined />} onClick={onClose}>
            <FormattedMessage id='app.action.close' defaultMessage='Close' />
          </Button>
        </React.Fragment>
      }
    >
      <Form form={form}>
        <Row gutter={8} style={{ marginBottom: '6px' }}>
          <Col {...labelLayout}>User Balance</Col>
          <Col {...valueLayout}>
            <FormattedNumber value={user?.balance.total ?? 0} minimumFractionDigits={2} />
          </Col>
        </Row>
        <Row style={{ marginBottom: '6px' }} />
        <Row gutter={8} style={{ marginBottom: '6px' }}>
          <Col {...labelLayout}>Requested Amount</Col>
          <Col {...valueLayout}>
            <FormattedNumber value={transferRequest?.amount ?? 0} minimumFractionDigits={2} />
          </Col>
        </Row>
        <Row gutter={8} style={{ marginBottom: '6px' }}>
          <Col {...labelLayout}>Request Date</Col>
          <Col {...valueLayout}>{moment(transferRequest.dateRequested).format('Do MMM YYYY, HH:mm:ss')}</Col>
        </Row>
        <Row style={{ marginBottom: '6px' }} />
        <Row gutter={8} style={{ marginBottom: '6px' }}>
          <Col {...labelLayout}><FormattedMessage id='app.account' defaultMessage='Account' /></Col>
          <Col {...valueLayout}>{`${user?.profile?.firstName} ${user?.profile?.lastName}`}</Col>
        </Row>
        <Row gutter={8} style={{ marginBottom: '6px' }}>
          <Col {...labelLayout}><FormattedMessage id='app.bankAccount' defaultMessage='Bank Account' /></Col>
          <Col {...valueLayout}>
            {user?.bankAccount?.number} ({user?.bankAccount?.name})
          </Col>
        </Row>
        <Row style={{ marginBottom: '6px' }} />
        {!!recipient && (
          <>
            <Row gutter={8} style={{ marginBottom: '6px' }}>
              <Col {...labelLayout}>Recipient Full Name</Col>
              <Col {...valueLayout}>{recipient?.name}</Col>
            </Row>
            <Row gutter={8} style={{ marginBottom: '6px' }}>
              <Col {...labelLayout}>Recipient Bank Account</Col>
              <Col {...valueLayout}>
                {leftPad(recipient?.bank_account?.last_digits ?? '', 10, 'X')} ({recipient?.bank_account?.name})
              </Col>
            </Row>
          </>
        )}
        <Form.Item name={['remark']} label={'Remark'}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

/**
 * balance
 *
 * requested Date
 * amount
 *
 *
 * user
 * - displayName
 * - fullName
 * - account (name, number)
 *
 * transfer to: recipient.name
 * transfer to account: (name, last digits)
 */
export default TransferRequestReviewModal
