import React from 'react'
import { useQuery } from '@apollo/client'
// import brokerQuery from '~/graphql/queries/broker.gql'
import brokerFinancialQuery from '~/graphql/queries/brokerFinancial.gql'
import { Spin } from 'antd'
import FinancialInfo from './Financialnfo'

const BrokerInfo = ({ brokerId }) => {
  const { error, loading, data } = useQuery(brokerFinancialQuery, {
    variables: {
      _id: brokerId
    }
  })
  if (loading) {
    return <Spin />
  }
  if (error) {
    return 'Error...'
  }
  const { share, transfer, balance } = data?.brokerFinancial ?? {}
  return <FinancialInfo share={share} transfer={transfer} balance={balance} />
}

export default BrokerInfo
