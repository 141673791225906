import React from 'react'
import { List, Spin } from 'antd'
import moment from 'moment'
// import _orderBy from 'lodash/orderBy'
// import { observer } from 'mobx-react'
import announcementsQuery from '~/graphql/queries/announcements.gql'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import Announcement from './Announcement'

// @graphql(announcementsQuery, {
//   name: 'announcementList'
// })
// @observer
const Announcements = ({ match }) => {
  const history = useHistory()
  const { error, loading, data } = useQuery(announcementsQuery)
  // const { announcements } = this.props.announcementList
  if (loading) {
    return <Spin />
  }
  if (error) {
    return 'Error'
  }
  const { announcements } = data || {}
  return (
    <div>
      {match.params.announcementId && <Announcement id={match.params.announcementId} />}
      <List
        style={{ padding: '10px' }}
        itemLayout="horizontal"
        dataSource={announcements}
        renderItem={item => (
          <List.Item onClick={() => history.push(`/announcements/${item._id}`)}>
            <List.Item.Meta
              // avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
              title={<span>{item.title}</span>}
              description={
                <div>
                  <span>{moment(item.createdAt).format('Do MMM YYYY, HH:mm:ss')}</span>
                  <p style={{
                    whiteSpace: 'pre-line',
                    display: '-webkit-box',
                    'WebkitLineClamp': '3',
                    'WebkitBoxOrient': 'vertical',
                    overflow: 'hidden'
                  }}>{item.message}</p>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default Announcements
